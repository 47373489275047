<template>
  <div class="cv-price">
    <div v-if="hasDiscount" class="cv-price__discount">
      <span class="cv-price__original">{{ $currency(originalValue) }}</span>
      <span class="cv-price__percentage cv-badge">{{ percentage }}</span>
    </div>
    <span class="cv-price__value">{{ $currency(value) }}<small v-if="price > 0">({{ $currency(price) }} + 10% VAT)</small></span>
  </div>
</template>

<script>
import { withVAT } from '../utils';

export default {
  props: {
    ticket: Object,
    originalPrice: {
      type: Number,
      required: false,
    },
    price: Number,
  },
  computed: {
    originalValue() {
      return withVAT(this.originalPrice)
    },
    hasDiscount() {
      if (this.originalPrice === undefined) {
        return false;
      }

      return this.originalValue > this.value;
    },
    value() {
      return withVAT(this.price)
    },
    percentage() {
      return `-${
        (100 / this.originalValue) *
        (this.originalValue - this.value)
      } %`;
    },
  },
};
</script>

<style lang="scss">
.cv-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.cv-price__discount {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.cv-price__percentage.cv-price__percentage {
  color: $color-white;
  background: #ff912c;
  font-family: "degular";
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  border-radius: 0%;
  padding: 4px;
  margin: unit(0.5) 0;
  margin-right: 1rem;
}
.cv-price__original,
.cv-price__value {
  font-size: unit(1.5);
}
.cv-price__original {
  text-decoration: line-through;
  color: #ff912c;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}
.cv-price__value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: $color-headings-dark;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: "degular";

  small {
    color: $color-body-2;
    font-size: 0.5em;
  }

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 32px;
  }
}
.cv-badge {
  margin: 0;
}
</style>
