<template>
  <div class="cv-tickets">
    <p
      v-if="couponError"
      class="cv-addcoupon__error cv-addcoupon__error-top cv-style--error"
    >
      The ticket code you tried to redeem is not valid. Please
      <a href="#redeem" @click="scrollToRedeem()">click</a> here to enter a valid code.
    </p>

    <h1 class="cv-text--intro cv-margin">
      {{ $t("tickets_all_options") }}
      {{ $t("tickets_more_options") }}
    </h1>

    <!-- <cv-infobox class="cv-margin">
      {{ $t("tickets_feel_free_to_repeat") }}
    </cv-infobox> -->

    <!-- <cv-infobox class="cv-margin" v-if="!hasPersonalTickets">
      {{ $t("currentlyNoTicketsInBasket") }}
    </cv-infobox> -->

    <!-- <cv-infobox class="cv-margin" v-if="personalTickets">
      {{ $t("personal_tickets_assigned") }}
    </cv-infobox> -->

    <!-- <cv-infobox class="cv-margin" v-if="personalTickets">
      {{ $t("member_of_oe1") }}
    </cv-infobox> -->

    <!-- Personal tickets -->
    <h2 class="cv-headline personal-ticket-headline">
      {{ $t("tickets_personal_tickets_headline") }}
    </h2>

    <p class="personal-ticket-p">
      {{ $t("personal_tickets_description") }}
    </p>

    <div
      v-for="ticket in personalTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <cv-ticketlist
        :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
        :approvalRequired="false"
      />
    </div>

    <h2 v-if="personalTicketsApproval" class="approval-required-headline">
      {{ $t("approval_headline") }}
    </h2>
    <cv-approval-required-info-box v-if="personalTicketsApproval" />

    <div
      v-for="ticket in personalTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <cv-ticketlist
        :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
        :approvalRequired="true"
      />
    </div>

    <!-- <cv-infobox class="cv-margin" v-if="additionalTickets">
      {{ $t("followingTicketsAreAdditional") }}
    </cv-infobox> -->

    <!-- Additional tickets -->
    <h2 class="cv-headline additional-ticket-headline" v-if="additionalTickets">
      {{ $t("additional_headline") }}
    </h2>
    <div
      v-for="ticket in additionalTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <div class="cv-tickets__voucher">
        <!-- <div class="cv-tickets__voucher-name">
          {{ ticket.efa_name }} | {{ ticket.code }}
        </div> -->
        <cv-ticketlist
          :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
          :approvalRequired="false"
          class="cv-tickets__voucher-items"
        />
      </div>
    </div>

    <h2 v-if="additionalTicketsApproval" class="approval-required-headline">
      {{ $t("approval_headline") }}
    </h2>
    <cv-approval-required-info-box v-if="additionalTicketsApproval" />

    <div
      v-for="ticket in additionalTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <div class="cv-tickets__voucher">
        <!-- <div class="cv-tickets__voucher-name">
          {{ ticket.efa_name }} | {{ ticket.code }}
        </div> -->
        <cv-ticketlist
          :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
          :approvalRequired="true"
          class="cv-tickets__voucher-items"
        />
      </div>
    </div>

    <!-- <cv-infobox class="cv-margin">
      {{ $t("free_tickets_are_confirmed") }}
    </cv-infobox> -->

    <!-- Public tickets -->
    <div class="public-tickets">
      <h2 class="cv-headline">
        {{ $t("tickets_public_tickets_headline") }}
      </h2>
      <div
        v-for="ticket in moduleTickets"
        :key="ticket._efa_ticketpermitid_value"
        class="cv-margin-small"
      >
        <cv-ticketlist :tickets="[ticket]" :approvalRequired="false" />
      </div>
    </div>

    <!-- Day pass tickets -->
    <div class="public-tickets cv-margin">
      <h2 class="cv-headline personal-ticket-headline">
        {{ $t("day_tickets_headline") }}
      </h2>
      <p class="personal-ticket-p">
        {{ $t("day_tickets_description") }}
      </p>
      <div
        v-for="ticket in dayPassTickets"
        :key="ticket._efa_ticketpermitid_value"
        class="cv-margin-small"
      >
        <cv-ticketlist :tickets="[ticket]" :approvalRequired="false" />
      </div>
    </div>

    <h2 v-if="publicTicketsApproval" class="approval-required-headline">
      {{ $t("approval_headline") }}
    </h2>
    <cv-approval-required-info-box v-if="publicTicketsApproval" />

    <div class="public-tickets-approval cv-margin">
      <div
        v-for="ticket in publicTickets"
        :key="ticket.efa_ticketpermitid"
        class="cv-margin-small"
      >
        <cv-ticketlist
          :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
          :approvalRequired="true"
        />
      </div>
    </div>

    <!-- <div
      v-for="ticket in publicTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <cv-ticketlist
        :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
        :approvalRequired="true"
      />
    </div> -->

    <!-- Approval required tickets -->
    <!-- <div
      v-if="approvalRequiredTickets.length"
      class="approval-required-headline"
    >
      {{ $t("approval_headline") }}
    </div>
    <cv-approval-required-info-box v-if="approvalRequiredTickets.length" />

    <div
      v-for="ticket in approvalRequiredTickets"
      :key="ticket.efa_ticketpermitid"
      class="cv-margin"
    >
      <cv-ticketlist
        :tickets="ticket.efa_ticketcategoryapproval_efa_ticketpermitid"
      />
    </div> -->

    <!-- Redeem codes box -->
    <div class="cv-redeem-toggler-wrapper">
      <div
        id="redeem"
        :class="{
          'cv-redeem-toggler': true,
          'cv-redeem-toggler-active': additionalExpanded | couponError,
        }"
        v-on:click="additionalExpanded = !additionalExpanded"
      >
        <h2 class="cv-headline">
          <div
            class="plus-minus-toggle"
            :class="{ 'close-icon': additionalExpanded }"
          ></div>
          <span>{{ $t("tickets_redeem_code") }}</span>
        </h2>
      </div>
      <div
        :class="{
          'cv-redeem-content': true,
          'cv-redeem-visible': additionalExpanded | couponError,
        }"
      >
        <cv-infobox class="cv-margin">
          {{ $t("codeNotNeeded") }}
        </cv-infobox>
        <cv-add-coupon class="cv-margin" />
      </div>

      <transition name="cv-slide-fade">
        <div v-show="selectedItems.length" class="cv-tickets__checkoutbar">
          <div class="cv-tickets__checkoutbar-inner cv-centered">
            <div class="cv-tickets__checkoutbar-label">
              {{ $t("tickets_checkout_label") }}
            </div>
            <div class="cv-tickets__checkoutbar-selection">
              <div class="cv-tickets__checkoutbar-count">
                {{
                  $t("tickets_checkout_ticket_count", {
                    count: selectedItems.length,
                  })
                }}
              </div>
              <div class="cv-tickets__checkoutbar-seperator"></div>
              <div class="cv-tickets__checkoutbar-price">
                <div class="cv-tickets__checkoutbar-price-value">
                  {{ price.total }}
                </div>
              </div>
            </div>
            <button class="cv-button" @click="checkout">
              {{ $t("tickets_next") }}
            </button>
          </div>
        </div>
      </transition>
      <div class="line"></div>
    </div>

    <!-- Back button to Contact Step -->
    <div class="cv-form__buttons">
      <div class="back-button btn">
        <router-link @click="setPrevStep" :to="{ name: 'Contact' }">
          <span>{{ $t("back_button_to_contact") }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CvAddCoupon from "../components/AddCouponForm.vue";
import CvTicketlist from "../components/Ticketlist.vue";
import CvInfobox from "../components/Infobox.vue";
import { mapGetters } from "vuex";
import CvApprovalRequiredInfoBox from "../components/ApprovalRequiredInfoBox.vue";
import { withVAT } from "../utils";

export default {
  data: function () {
    return {
      additionalExpanded: false,
    };
  },
  components: {
    CvAddCoupon,
    CvTicketlist,
    CvInfobox,
    CvApprovalRequiredInfoBox,
  },
  computed: {
    ...mapGetters({
      personalTickets: "ticket/personalTickets",
      allItems: "ticket/allItems",
      publicTickets: "ticket/publicTickets",
      additionalTickets: "ticket/additionalTickets",
      approvalRequiredTickets: "ticket/approvalRequiredTickets",
      selectedItems: "ticket/selectedItems",
      publicTicketsApproval: "ticket/publicTicketsApproval",
      personalTicketsApproval: "ticket/personalTicketsApproval",
      additionalTicketsApproval: "ticket/additionalTicketsApproval",
      ticketsFirstMounted: "ticket/ticketsFirstMounted",
    }),
    couponError() {
      return this.$store.state.ticket.couponError;
    },
    dayPassTickets() {
      const tickets = this.publicTickets
        .flatMap(
          (ticket) => ticket.efa_ticketcategoryapproval_efa_ticketpermitid
        )
        .filter(
          (ticket) =>
            ticket[
              "_efa_ticketpermitid_value@OData.Community.Display.V1.FormattedValue"
            ]?.indexOf("August") !== -1
        );

      return tickets || [];
    },
    moduleTickets() {
      const tickets = this.publicTickets
        .flatMap(
          (ticket) => ticket.efa_ticketcategoryapproval_efa_ticketpermitid
        )
        .filter(
          (ticket) =>
            ticket[
              "_efa_ticketpermitid_value@OData.Community.Display.V1.FormattedValue"
            ]?.indexOf("August") === -1
        );

      return tickets || [];
    },
    price() {
      let price = 0;
      let total = 0;
      this.selectedItems.forEach((item) => {
        price += item.efa_price;
        total += item.efa_totalprice;
      });

      const priceWithVAT = withVAT(price);
      const totalWithVAT = withVAT(total);

      let percentage = (
        (100 / priceWithVAT) *
        (priceWithVAT - totalWithVAT)
      ).toFixed(2);
      return {
        hasDiscount: priceWithVAT > totalWithVAT,
        original: `€ ${priceWithVAT}`,
        total: `€ ${totalWithVAT}`,
        percentage: `-${percentage}%`,
      };
    },
    hasPersonalTickets() {
      if (this.personalTickets.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    hasNoneTemplateTickets() {
      var result = true;
      this.allItems.forEach((item) => {
        if (item.efa_istemplate == false) {
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    checkout() {
      this.$store.dispatch("ticket/goToBasket");
    },
    scrollToRedeem(e) {
      e.preventDefault();

      this.additionalExpanded = true;
      var container = document.querySelector(".cv-redeem-toggler");
      container.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    },
    // Set prev step by clicking on back button
    setPrevStep() {
      this.$store.commit("step", 3);
    },
  },
  mounted() {
    if (this.ticketsFirstMounted) {
      this.$store.commit("ticket/ticketsFirstMounted", false);
    }
  },
};
</script>

<style lang="scss">
.cv-tickets__voucher-name {
  display: inline-flex;
  background: rgba($color-gray, 0.2);
  padding: unit(0.2) unit(0.5);
  border-radius: 5px 5px 0 0;
}
.cv-tickets__checkoutbar {
  position: fixed;
  z-index: 199;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "degular";
  background-color: $color-yearly-accent;

  .cv-button {
    background-color: black;
    color: white;
    border-radius: 2px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 18px;
    line-height: 23.89px;
    display: flex;
    font-family: "degular";
    font-weight: 600;

    @media (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:hover,
    &:focus {
      color: white;

      .input {
        background-color: black;
      }
    }

    &:disabled,
    &.disabled {
      color: $color-grey-500;
    }

    &::after {
      content: "";
      background-image: url("../assets/sass/ic_arrow_right.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      height: 24px;
      width: 40px;
      display: block;
      margin-left: 16px;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      @media (min-width: 768px) {
        width: 24px;
      }
    }

    &:hover {
      &::after {
        margin-left: 22px;
      }
    }
  }
}
.cv-tickets__checkoutbar-inner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: unit(1) unit(2);
  color: $color-white;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    padding: 0;
  }

  .cv-buttons {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.cv-tickets__checkoutbar-label {
  font-family: "degular";
  font-size: 32px;
  line-height: 34px;
  font-weight: 700;
}

.cv-tickets__checkoutbar-selection {
  font-family: "degular";
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  display: flex;

  @media (max-width: 768px) {
    padding-left: 8px;
  }
}

.cv-tickets__checkoutbar-seperator {
  padding: 0 unit(1);

  &:before {
    content: "·";
  }
}

.cv-slide-fade-enter-active,
.cv-slide-fade-leave-active {
  transition: all 0.4s;
}
.cv-slide-fade-enter-from,
.cv-slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.cv-redeem-toggler-wrapper {
  margin-bottom: 40px;
}
.cv-redeem-toggler-wrapper .cv-headline {
  display: flex;
  align-items: center;
}
.cv-redeem-toggler-wrapper .line {
  width: 100%;
  height: 1px;
  background: black;
}

.cv-redeem-toggler {
  cursor: pointer;
}

.cv-redeem-content:not(.cv-redeem-visible) {
  display: none;
}

.cv-addcoupon__error-top a {
  text-decoration: underline;
}

.cv-addcoupon__error-top {
  padding-bottom: 20px;
}
.cv-headline.personal-ticket-headline {
  margin-bottom: 16px;
}
.personal-ticket-p {
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #303139;
  margin-top: 0;
  margin-bottom: 40px;
}

.cv-tickets .cv-form__buttons {
  .back-button {
    margin-bottom: 100px;

    a {
      position: relative;
      background-color: white;
      color: black;
      border-radius: 2px;
      border-color: black;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 32px;
      padding-right: 32px;
      display: flex;
      justify-content: center;
      text-decoration: none;
      border-style: solid;

      &:hover,
      &:focus {
        color: black;

        .input {
          background-color: white;
        }
      }

      &:disabled,
      &.disabled {
        color: $color-grey-500;
      }

      &::before {
        content: "";
        background-image: url("../assets/sass/ic_arrow_left.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        height: 24px;
        width: 40px;
        display: block;
        margin-right: 16px;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        @media (min-width: 768px) {
          width: 24px;
        }
      }

      &:hover {
        &::before {
          margin-right: 22px;
        }
      }

      span {
        font-family: "degular";
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }

      input {
        position: absolute;
        inset: 0;
        background-color: transparent;
        font-size: 0;
      }
    }
  }
}

.cv-tickets .plus-minus-toggle {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv-tickets .plus-minus-toggle:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: $color-yearly-accent;
  display: inline-block;
}

.cv-tickets .plus-minus-toggle:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: $color-yearly-accent;
  display: inline-block;
}

.cv-tickets .plus-minus-toggle.close-icon:after {
  margin-top: 0px;
  width: 0;
  height: 0;
}

.cv-tickets .plus-minus-toggle:before,
.cv-tickets .plus-minus-toggle:after {
  transition: all 0.3s ease;
}

.cv-tickets .approval-required-headline {
  font-family: "Degular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $color-headings-dark;
  margin-bottom: 8px;
}

.cv-margin-small {
  margin-bottom: 30px;
}
</style>
