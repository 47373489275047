<template>
  <header class="cv-step-navigation">
    <div
      v-for="(step, index) in steps"
      :key="step.number"
      class="cv-step"
      :class="{
        'cv-step--active': active > index,
        'cv-step-invitation': invitation,
      }"
    >
      <span class="cv-step__number">{{ step.number }}</span>
      <span class="cv-step__name">{{ $t(step.name) }}</span>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    active() {
      return this.$store.state.step;
    },
    invitation() {
      return this.$store.getters.invitation;
    },
    steps() {
      const steps = [];

      if (this.invitation) {
        steps.push(
          {
            number: "01",
            name: "step_verification",
          },
          {
            number: "02",
            name: "step_registration",
          },
          {
            number: "03",
            name: "step_invitations",
            routing: "contact",
          }
        );
      } else {
        steps.push(
          {
            number: "01",
            name: "step_verification",
          },
          {
            number: "02",
            name: "step_registration",
          },
          {
            number: "03",
            name: "step_contact",
            routing: "contact",
          },
          {
            number: "04",
            name: "step_tickets",
          },
          {
            number: "05",
            name: "step_basket",
          }
        );
      }

      return steps;
    },
  },
};
</script>

<style lang="scss">
.cv-step-navigation {
  display: flex;

  @media (max-width: 768px) {
    margin-left: -8px;
    margin-right: -8px;
  }
}
.cv-step {
  position: relative;
  width: 25%;
  padding: unit(1.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 21.23px;
  letter-spacing: 1%;
  font-weight: medium;
  color: $color-body-1;

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    background: $color-grey-200;
    height: 8px;
    transition: all $duration-default;
  }

  &.cv-step-invitation {
    width: 33.3%;
  }

  &.cv-step--active {
    &:after {
      background: black;
    }

    .cv-step__name {
      color: $color-body-1;
      font-family: "degular";
      font-weight: "500";
    }
  }
}
.cv-step__number {
  margin-right: 8px;
  color: $color-headings-dark;
  font-family: "degular";
  font-weight: "500";
}
.cv-step__name {
  color: $color-body-1;
  font-family: "degular";
  font-weight: "500";
}
</style>
