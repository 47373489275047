<template>
  <div class="cv-addcoupon cv-formbox">
    <cv-form
      class="cv-form--singleline"
      @submit="submitData"
      :submitValue="'submit_coupon'"
    >
      <h3 class="cv-headline">{{ $t("addcoupon_input") }}</h3>
      <cv-input-field v-model="coupon" :required="true" />
    </cv-form>
    <p v-if="error" class="cv-addcoupon__error cv-style--error">
      Your ticket code is not valid.
    </p>
  </div>
</template>

<script>
import CvForm from "../components/form/Form.vue";
import CvInputField from "../components/form/InputField.vue";

export default {
  components: {
    CvForm,
    CvInputField,
  },
  computed: {
    error() {
      return this.$store.state.ticket.couponError;
    },
  },
  data: () => ({
    coupon: "",
  }),
  methods: {
    submitData() {
      this.$store.dispatch("ticket/addCoupon", this.coupon);
    },
  },
};
</script>

<style lang="scss">
.cv-addcoupon__error {
  margin-top: unit(1);
}
</style>
