import * as Sentry from "@sentry/vue";

import router from "../../router";
import api from "../../api";
import { billingAdress, uuid } from "../../utils";
import { initStripe } from "../../utils/stripe";
import { isStateCompleted } from "../../utils/ticketstates";
import { VAT } from "../../config";

const isSameTicket = function (a, b) {
  return a.efa_ticketcategoryapprovalid == b.efa_ticketcategoryapprovalid;
};

const defaultState = {
  loaded: false,
  tickets: [],
  selected: [],
  paymentData: null,
  submitedTickets: [],
  couponError: false,
  sepaDeadline: null,
  ticketsFirstMounted: true,

  // Store the cheapest tickets for later checking if the ticket should even be shown.
  // Duplicate tickets with a higher price should not be shown.
  cheapestTickets: [],
};

const state = Object.assign({}, defaultState);

const mutations = {
  reset: (state) => {
    Object.assign(state, defaultState);
  },
  initialized: (state) => {
    state.loaded = true;
  },
  addTicket: (state, ticket) => {
    window.lintrk?.("track", { conversion_id: 16133148 });

    ticket.efa_ticketcategoryapproval_efa_ticketpermitid.forEach((ticket) => {
      const inCheapestTicket = state.cheapestTickets.findIndex((ct) => {
        return equalTickets(ticket, ct);
      });

      if (inCheapestTicket === -1) {
        state.cheapestTickets.push(ticket);
      }

      state.cheapestTickets = state.cheapestTickets.map((ct) => {
        if (!equalTickets(ct, ticket)) {
          return ct;
        }

        return ticket.efa_totalprice < ct.efa_totalprice ? ticket : ct;
      });
    });

    const hasTicket = state.tickets.findIndex((t) => {
      return t.efa_ticketpermitid === ticket.efa_ticketpermitid;
    });

    if (hasTicket === -1) {
      state.tickets.push(ticket);
    }
  },
  select: (state, item) => {
    state.selected.push(item);
  },
  unselect: (state, item) => {
    state.selected = state.selected.filter(
      (selectedItem) => !isSameTicket(selectedItem, item)
    );
  },
  paymentData: (state, payload) => {
    state.paymentData = payload;
  },
  submitedTickets: (state, payload) => {
    state.submitedTickets = payload;
  },
  couponError: (state, value) => {
    state.couponError = value;
  },
  sepaDeadline: (state, value) => {
    state.sepaDeadline = value;
  },
  ticketsFirstMounted: (state, value) => {
    state.ticketsFirstMounted = value;
  },
  cheapestTickets: (state, value) => {
    state.cheapestTickets = value;
  },
};

const getters = {
  get: (state) => (id) => {
    return state.tickets.find((item) => item.efa_ticketpermitid == id);
  },
  isPersonal: () => (ticket) => {
    return !ticket.efa_istemplate;
  },
  isPublic: () => (ticket) => {
    return ticket.efa_istemplate && ticket.efa_ispublic;
  },
  isVoucher: () => (ticket) => {
    return ticket.efa_istemplate && !ticket.efa_ispublic;
  },
  sepaDeadline: (state) => {
    return state.sepaDeadline;
  },
  ticketsFirstMounted: (state) => {
    return state.ticketsFirstMounted;
  },
  personalTickets: (state, { isPersonal }) => {
    return state.tickets.filter(isPersonal);
  },
  cheapestTickets: (state) => {
    return state.cheapestTickets;
  },
  publicTickets: (state, { isPublic }) => {
    return state.tickets.filter(isPublic);
  },
  publicTicketsApproval: (state, { isPublic }) => {
    const publicTickets = state.tickets.filter(isPublic);
    const publicItems = [];
    let needsApproval = false;

    publicTickets.forEach((ticketPermit) => {
      publicItems.push(
        ...ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid
      );
    });
    publicItems.forEach((item) => {
      if (item["efa_requiresapproval"] === true) {
        needsApproval = true;
      }
    });
    return needsApproval;
  },
  personalTicketsApproval: (state, { isPersonal }) => {
    const personalTickets = state.tickets.filter(isPersonal);
    const personalItems = [];
    let needsApproval = false;

    personalTickets.forEach((ticketPermit) => {
      personalItems.push(
        ...ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid
      );
    });
    personalItems.forEach((item) => {
      if (item["efa_requiresapproval"] === true) {
        needsApproval = true;
      }
    });
    return needsApproval;
  },
  additionalTickets: (state, { isVoucher }) => {
    return state.tickets.filter(isVoucher);
  },
  additionalTicketsApproval: (state, { isVoucher }) => {
    const additionalTickets = state.tickets.filter(isVoucher);
    const additionalItems = [];
    let needsApproval = false;

    additionalTickets.forEach((ticketPermit) => {
      additionalItems.push(
        ...ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid
      );
    });
    additionalItems.forEach((item) => {
      if (item["efa_requiresapproval"] === true) {
        needsApproval = true;
      }
    });
    return needsApproval;
  },
  approvalRequiredTickets: (state) => {
    const approvalRequiredItems = [];
    const items = [];
    state.tickets.forEach((ticketPermit) =>
      items.push(...ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid)
    );
    items.forEach((item) => {
      if (item["efa_requiresapproval"] === true) {
        approvalRequiredItems.push(item);
      }
    });
    return approvalRequiredItems;
  },
  allItems: (state) => {
    let items = [];
    state.tickets.forEach((ticketPermit) =>
      items.push(...ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid)
    );
    return items;
  },
  selectedItems: (state) => {
    return state.selected;
  },
  selectedItemsPayable: (state) => {
    // filter items that need no approval or need approval but are permitted (statuscode 221120001)
    return state.selected.filter(
      (item) =>
        (item["efa_requiresapproval"] === false && item["efa_price"] > 0) ||
        (item["efa_requiresapproval"] === true &&
          item["statuscode"] === 221120001 &&
          item["efa_price"] > 0)
    );
  },
  selectedItemsApprovalRequired: (state) => {
    // filter all items that need approval and are not permitted (statuscode 221120001)
    return state.selected.filter(
      (item) =>
        item["efa_requiresapproval"] === true &&
        item["statuscode"] !== 221120001 &&
        item["efa_price"] > 0
    );
  },
  selectedItemsConfirmed: (state) => {
    return state.selected.filter(
      (item) => item["efa_requiresapproval"] === false && item["efa_price"] == 0
    );
  },
  paidOrPendingItems: (state, { allItems }) => {
    return allItems.filter(
      (item) => isStateCompleted(item) || item.statuscode == 221120005
    );
  },
  isSelected: (state) => (item) => {
    return !!state.selected.find((selectedItem) =>
      isSameTicket(selectedItem, item)
    );
  },
  selectedSessions: (state) => {
    let sessions = [];
    state.selected.forEach((selectedItem) => {
      sessions.push(
        ...selectedItem.efa_ticketcategory.efa_ticketcategorysessiontracks
      );
    });
    return sessions;
  },
  isSessionSelected: (state, { selectedSessions }) => (session) => {
    return !!selectedSessions.find(
      (selectedSession) =>
        selectedSession.msevtmgt_sessiontrackid ==
        session.msevtmgt_sessiontrackid
    );
  },
  billingData: (state, getters, rootState) => {
    return billingAdress(rootState.contact);
  },
  similarTickets: (state) => (ticket) => {
    let similarTickets = [];
    state.tickets.forEach((ticketPermit) => {
      ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid.forEach(
        (ticketApproval) => {
          if (
            ticket._efa_ticketcategoryid_value ==
              ticketApproval._efa_ticketcategoryid_value &&
            !isSameTicket(ticket, ticketApproval)
          ) {
            similarTickets.push(ticketApproval);
          }
        }
      );
    });
    return similarTickets;
  },
  similarTicketsSelected: (state, { similarTickets, selectedItems }) => (
    ticket
  ) => {
    let similarItems = similarTickets(ticket);
    return selectedItems.filter((selectedItem) => {
      return similarItems.find((similarTicket) =>
        isSameTicket(selectedItem, similarTicket)
      );
    });
  },
  similarTicketsPaid: (state, { similarTickets, paidOrPendingItems }) => (
    ticket
  ) => {
    let similarItems = similarTickets(ticket);
    return paidOrPendingItems.filter((paidItem) => {
      return similarItems.find((similarTicket) =>
        isSameTicket(paidItem, similarTicket)
      );
    });
  },
};

// const mockGetters = {
//   ...getters,
//   billingData: () => ({
//     name: "allaboutapps",
//     address: {
//       company: "aaa – all about apps GmbH",
//       street: "Mollardgasse 70C / TOP 5",
//       zip: "1060",
//       country: "Austria",
//     },
//   }),

//   personalTickets: () => [
//     {
//       efa_ticketpermitid: 1,
//       efa_ticketcategoryapproval_efa_ticketpermitid: [
//         {
//           efa_requiresapproval: false,
//           efa_name: "EFA24 - AlpSEM - Participant",
//           "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue":
//             "EFA24 - AlpSEM - Participant",
//           efa_totalprice: 1255.55,
//           efa_price: 1255.55,
//           "efa_totalprice@OData.Community.Display.V1.FormattedValue":
//             "€ 1255.55",
//           efa_ticketcategory: {
//             "@odata.etag": 'W/"277655734"',
//             efa_ticketcategoryid: "8ed291a6-f87e-ee11-8179-6045bd905923",
//             efa_description: null,
//             "efa_validfrom@OData.Community.Display.V1.FormattedValue":
//               "27.08.2024",
//             efa_validfrom: "2024-08-27T00:00:00Z",
//             "efa_validto@OData.Community.Display.V1.FormattedValue":
//               "30.08.2024",
//             efa_validto: "2024-08-30T00:00:00Z",
//             "efa_soldout@OData.Community.Display.V1.FormattedValue": "Nein",
//             efa_soldout: false,
//             "efa_partnerinvitation@OData.Community.Display.V1.FormattedValue":
//               "Nein",
//             efa_partnerinvitation: false,
//             efa_ticketcategorysessiontracks: [],
//             efa_ticketcategory_efa_ticketcategory_efa: [],
//           },
//           "statuscode@OData.Community.Display.V1.FormattedValue": "30.08.2024",
//         },
//         {
//           efa_requiresapproval: false,
//           efa_name: "EFA24 - LAB",
//           "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue":
//             "EFA24 - LAB",
//           efa_totalprice: 1000,
//           efa_price: 1000,
//           "efa_totalprice@OData.Community.Display.V1.FormattedValue": "€ 1000",
//           efa_ticketcategory: {
//             "@odata.etag": 'W/"277655812"',
//             efa_ticketcategoryid: "bdf942c2-f57e-ee11-8179-6045bdf655f6",
//             efa_description: null,
//             "efa_validfrom@OData.Community.Display.V1.FormattedValue":
//               "19.08.2024",
//             efa_validfrom: "2024-08-19T08:00:00Z",
//             "efa_validto@OData.Community.Display.V1.FormattedValue":
//               "24.08.2024",
//             efa_validto: "2024-08-24T08:00:00Z",
//             "efa_soldout@OData.Community.Display.V1.FormattedValue": "Nein",
//             efa_soldout: false,
//             "efa_partnerinvitation@OData.Community.Display.V1.FormattedValue":
//               "Nein",
//             efa_partnerinvitation: false,
//             efa_ticketcategorysessiontracks: [],
//             efa_ticketcategory_efa_ticketcategory_efa: [],
//           },
//           "statuscode@OData.Community.Display.V1.FormattedValue": "24.08.2024",
//         },
//         {
//           efa_requiresapproval: false,
//           efa_name: "EFA24 - EiW - Participant",
//           "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue":
//             "EFA24 - EiW - Participant",
//           efa_totalprice: 0,
//           efa_price: 1000,
//           "efa_totalprice@OData.Community.Display.V1.FormattedValue": "€ 1000",
//           efa_ticketcategory: {
//             "@odata.etag": 'W/"277655795"',
//             efa_ticketcategoryid: "44c883fc-f47e-ee11-8179-6045bdf655f6",
//             efa_description: null,
//             "efa_validfrom@OData.Community.Display.V1.FormattedValue":
//               "17.08.2024",
//             efa_validfrom: "2024-08-17T00:00:00Z",
//             "efa_validto@OData.Community.Display.V1.FormattedValue":
//               "18.08.2024",
//             efa_validto: "2024-08-18T00:00:00Z",
//             "efa_soldout@OData.Community.Display.V1.FormattedValue": "Nein",
//             efa_soldout: false,
//             "efa_partnerinvitation@OData.Community.Display.V1.FormattedValue":
//               "Nein",
//             efa_partnerinvitation: false,
//             efa_ticketcategorysessiontracks: [],
//             efa_ticketcategory_efa_ticketcategory_efa: [],
//           },
//           "statuscode@OData.Community.Display.V1.FormattedValue": "18.08.2024",
//         },
//       ],
//     },
//   ],

//   similarTickets: () => () => [],
//   similarTicketsPaid: () => () => [],
//   similarTicketsSelected: () => () => [],

//   selectedItems: () => {
//     return [
//       {
//         efa_name: "EFA24 - EiW - Participant",
//         efa_totalprice: 1000,
//         "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//       },
//     ];
//   },

//   selectedItemsConfirmed: () => [
//     {
//       efa_name: "EFA24 - EUR - Participant",
//       efa_totalprice: 0,
//       "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//     },
//     {
//       efa_name: "EFA24 - Entire Forum - Staff",
//       efa_totalprice: 0,
//       "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//     },
//   ],

//   selectedItemsPayable: () => [
//     {
//       efa_name: "EFA24 - AlpSEM - Participant",
//       efa_totalprice: 1255.55,
//       "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//     },
//     {
//       efa_name: "EFA24 - LAB",
//       efa_totalprice: 1000,
//       "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//     },
//     {
//       efa_name: "EFA24 - EiW - Participant",
//       efa_totalprice: 1000,
//       "statuscode@OData.Community.Display.V1.FormattedValue": "25.03.2014",
//     },
//   ],
// };

const actions = {
  initialize({ commit, state, dispatch }) {
    if (!state.loaded) {
      commit("initialized");
      dispatch("loadPersonalTickets");
    } else {
      //TODO reload tickets
      router.push("/tickets");
    }
  },
  loadPersonalTickets({ commit, dispatch, rootState }) {
    commit("loading", true, { root: true });
    api
      .fetchTicktDetails(rootState.tokenJWT)
      .then((ticketPermits) => {
        // console.log("loadPersonalTickets: loaded ticket permits", {
        //   ticketPermitsSize: ticketPermits.length,
        //   ticketPermits: ticketPermits,
        // });
        commit("loading", false, { root: true });

        // Load the cheapest tickets of all available tickets in the different ticket permits.
        // A ticket is the same ticket if the category id and the status matches.
        let cheapestTickets = [];
        // let totalTickets = 0;
        // let ticketsThatShouldBeShown = 0;
        ticketPermits.forEach((ticketPermit) => {
          ticketPermit.efa_ticketcategoryapproval_efa_ticketpermitid.forEach(
            (ticket) => {
              // totalTickets++;
              const alreadyAddedTicket = cheapestTickets.find((t) => {
                return (
                  ticket["efa_ticketcategory"]["efa_ticketcategoryid"] ==
                    t["efa_ticketcategory"]["efa_ticketcategoryid"] &&
                  ticket.statuscode == t.statuscode
                );
              });
              if (alreadyAddedTicket) {
                if (alreadyAddedTicket.efa_totalprice > ticket.efa_totalprice) {
                  // console.log(
                  //   "filter if ticket already exists, replace ticket because cheaper ticket found",
                  //   ticket[
                  //     "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue"
                  //   ],
                  //   ticket
                  // );
                  cheapestTickets = cheapestTickets.filter((vt) => {
                    return !(
                      ticket["efa_ticketcategory"]["efa_ticketcategoryid"] ==
                        vt["efa_ticketcategory"]["efa_ticketcategoryid"] &&
                      ticket.statuscode == vt.statuscode
                    );
                  });
                  cheapestTickets.push(ticket);
                  // ticketsThatShouldBeShown++;
                } else {
                  // if (
                  //   alreadyAddedTicket.efa_totalprice === ticket.efa_totalprice
                  // ) {
                  //   ticketsThatShouldBeShown++;
                  // }
                  // console.log(
                  //   alreadyAddedTicket.efa_totalprice === ticket.efa_totalprice
                  //     ? "ticket with same price already part of list"
                  //     : "skip ticket because cheaper ticket exists",
                  //   {
                  //     ticketCategoryId:
                  //       ticket[
                  //         "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue"
                  //       ],
                  //     ticket: ticket,
                  //   }
                  // );
                }
              } else {
                cheapestTickets.push(ticket);
                // ticketsThatShouldBeShown++;
              }
            }
          );
        });
        // console.log("loadPersonalTickets: filtered cheapest tickets", {
        //   totalTicketsSize: totalTickets,
        //   ticketsThatShouldBeShown: ticketsThatShouldBeShown,
        //   cheapestTicketsSize: cheapestTickets.length,
        //   cheapestTickets: cheapestTickets,
        // });
        commit("cheapestTickets", cheapestTickets);

        // Store the ticket permits in the store.
        ticketPermits.forEach((item) => {
          commit("addTicket", {
            code: rootState.ticketPermit.code,
            ...item,
          });
        });

        commit("step", 4, { root: true });
        router.push("/tickets");
      })
      .catch(() => {
        commit("loading", false, { root: true });
        dispatch("error", "Personal tickets not loaded, please try again.", {
          root: true,
        });
      });
  },
  addCoupon({ commit, rootState }, coupon) {
    commit("couponError", false);
    commit("loading", true, { root: true });
    api
      .fetchTicktDetails(rootState.tokenJWT, coupon)
      .then((ticketPermits) => {
        commit("loading", false, { root: true });
        ticketPermits.forEach((item) => {
          commit("addTicket", {
            code: coupon,
            ...item, //TODO
          });
        });
      })
      .catch(() => {
        commit("loading", false, { root: true });
        commit("couponError", true);
      });
  },
  selectItem({ commit, getters }, item) {
    if (!getters.isSelected(item)) {
      commit("select", item);
    }
  },
  unselectItem({ commit, getters }, item) {
    if (getters.isSelected(item)) {
      commit("unselect", item);
    }
  },
  goToBasket({ commit }) {
    router.push("/basket");
    commit("step", 5, { root: true });
  },
  checkout({ commit, getters, rootState, dispatch }) {
    window.lintrk?.("track", { conversion_id: 16133156 });
    commit("loading", true, { root: true });
    let codes = [];
    let items = [];
    getters.selectedItems.forEach((item) => {
      let ticket = getters.get(item._efa_ticketpermitid_value);
      let ticketCodeLink = ticket.efa_ticketpermitlink_efa_ticketpermitid[0];
      let code = ticketCodeLink ? ticketCodeLink.efa_externalcode : null;
      // wenn personal oder public, werden nur die gewählten Items mitgegeben
      if (getters.isPersonal(ticket) || getters.isPublic(ticket)) {
        if (code && !codes.includes(code)) {
          codes.push(code);
        }
        items.push({
          efa_ticketcategoryapprovalid: item.efa_ticketcategoryapprovalid,
          isselected: true,
        });
      }
      // wenn ein voucher, werden immer alle Items im Ticket mitgegeben und mit true/false markiert
      else if (!codes.includes(code)) {
        codes.push(code);
        let ticketItems = ticket.efa_ticketcategoryapproval_efa_ticketpermitid.map(
          (ticketItem) => ({
            efa_ticketcategoryapprovalid:
              ticketItem.efa_ticketcategoryapprovalid,
            isselected: getters.isSelected(ticketItem),
          })
        );
        items.push(...ticketItems);
      }
    });

    let requestData = {
      efa_ticketpermitid: rootState.ticketPermit.data.efa_ticketpermitid,
      efa_contactid: rootState.contact.contactid,
      externalcodes: codes,
      efa_ticketcategoryapproval_efa_ticketpermitid: items,
    };

    api
      .checkoutTickets(rootState.tokenJWT, requestData)
      .then((result) => {
        commit("loading", false, { root: true });
        commit("sepaDeadline", result["sepadeadline"]);
        if (result.statuscode == "SurveyRequired") {
          router.push("/checkout/survey");
        } else if (result.statuscode == "ApprovalRequired") {
          router.push("/checkout/approval");
        } else {
          commit("submitedTickets", result);
          dispatch("initPayment", result);
        }
      })
      .catch(() => {
        commit("loading", false, { root: true });
        dispatch("error", "Checkout failed, please try again.", { root: true });
      });
  },
  initPayment({ commit, dispatch, rootState }, payload) {
    commit("loading", true, { root: true });
    commit("paymentData", null);
    Promise.all([
      initStripe(),
      api.createPaymentIntent(rootState.tokenJWT, payload),
    ])
      .then((promiseResult) => {
        commit("loading", false, { root: true });
        let paymentData = promiseResult[1]; //[stripe, paymantData]
        if (paymentData.amount == 0) {
          router.push("/checkout/complete");
        } else {
          commit("paymentData", paymentData);
          router.push("/checkout/payment");
        }
      })
      .catch(() => {
        commit("loading", false, { root: true });
        dispatch("error", "Checkout failed, please try again.", { root: true });
      });
  },

  paymentSucceded() {
    router.push("/checkout/complete");
  },

  paymentComplete({ rootState, getters }) {
    try {
      window.lintrk?.("track", { conversion_id: 16178420 });

      const items = getters.selectedItems
        .map((item) =>
          rootState.ticketLinks.find(
            (tl) => tl.ticket === item._efa_ticketpermitid_value
          )
        )
        .filter(Boolean)
        .map((item) => ({
          item_id: item.externalcode,
          item_name: item["ticket@OData.Community.Display.V1.FormattedValue"],
          price: item.price,
          quantity: 1,
        }));

      const value = items.reduce((acc, i) => acc + i.price, 0);
      const tax = value * VAT;

      console.log({
        transaction_id: uuid(),
        value: value,
        tax: tax,
        currency: "EUR",
        items: items,
      });

      window.gtag?.("event", "purchase", {
        transaction_id: uuid(),
        value: value,
        tax: tax,
        currency: "EUR",
        items: items,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters: getters,
  actions,
};

function equalTickets(lhs, rhs) {
  return (
    lhs?.efa_ticketcategory?.efa_ticketcategoryid ==
      rhs?.efa_ticketcategory?.efa_ticketcategoryid &&
    lhs.statuscode == rhs.statuscode
  );
}
