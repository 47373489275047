<template>
  <ul class="cv-ticketlist">
    <li
      class="cv-ticketlist__item"
      v-for="ticket in sortedTickets"
      :key="ticket.efa_ticketcategoryapprovalid"
    >
      <cv-ticket
        :ticket="ticket"
        v-if="ticket['efa_requiresapproval'] === approvalRequired"
      ></cv-ticket>
    </li>
  </ul>
</template>

<script>
import CvTicket from "./Ticket.vue";
import { sortByState } from "../utils/ticketstates";
import { useMock } from "../utils";

export default {
  props: {
    tickets: Array,
    approvalRequired: Boolean,
  },
  components: {
    CvTicket,
  },
  computed: {
    sortedTickets() {
      if (process.env.NODE_ENV === "development" && useMock()) {
        return this.tickets;
      }

      const filteredSortedTickets = this.tickets
        .filter((ticket) => {
          const foundCheapestTicket = this.cheapestTickets.find((ct) => {
            return (
              ticket["efa_ticketcategory"]["efa_ticketcategoryid"] ==
                ct["efa_ticketcategory"]["efa_ticketcategoryid"] &&
              ticket.statuscode == ct.statuscode &&
              ticket.efa_totalprice == ct.efa_totalprice
            );
          });

          return !!foundCheapestTicket;
        })
        .sort(sortByState);

      return filteredSortedTickets;
    },
    cheapestTickets() {
      return this.$store.getters["ticket/cheapestTickets"];
    },
  },
};
</script>

<style lang="scss">
.cv-ticketlist {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cv-ticketlist__item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
