<template>
  <div class="cv-basket">
    <header class="cv-text--intro cv-margin">
      <h2 tabindex="0" class="cv-style--highlight">{{ $t("basket_headline") }}</h2>
      <section tabindex="0" aria-label="Billing Details" class="billing-details">
        <div v-if="billingData.address.company">
          {{ billingData.address.company }}
        </div>
        <div>{{ billingData.name }}</div>
        <!-- <div>{{ billingData.mail }}</div> -->
        <div>{{ billingData.address.street }}</div>
        <div>{{ billingData.address.zip }} {{ billingData.address.city }}</div>
        <div>{{ billingData.address.country }}</div>
        <div v-if="billingData.address.uid">
          {{ billingData.address.uid }}
        </div>
      </section>
    </header>

    <!-- Confirmed tickets -->
    <section tabindex="0" :aria-label="$t('payment_confirmed_tickets')" v-if="selectedItemsConfirmed.length">
      <h3 class="cv-headline">{{ $t("payment_confirmed_tickets") }}</h3>
      <cv-ticket-summery
        class="cv-margin"
        :tickets="selectedItemsConfirmed"
        :showDeleteButton="true"
        :price="false"
      />
    </section>

    <!-- Approval required tickets -->
    <section tabindex="0" :aria-label="$t('needs_approval')" v-if="selectedItemsApprovalRequired.length">
      <h3 class="cv-headline">{{ $t('needs_approval') }}</h3>
      <cv-ticket-summery
        class="cv-margin"
        :tickets="selectedItemsApprovalRequired"
        :showDeleteButton="true"
        :price="false"
      />
    </section>

    <!-- Payable tickets -->
    <section tabindex="0" :aria-label="$t('payable')" v-if="selectedTicketsPayable.length">
      <h3 class="cv-headline">{{ $t("payable") }}</h3>
      <cv-ticket-summery
        class="cv-margin"
        :tickets="selectedTicketsPayable"
        :showDeleteButton="true"
      />
    </section>

    <!-- Approval required Infobox  -->
    <cv-approval-required-info-box
      v-if="selectedItemsApprovalRequired.length"
    />

    <!-- Back button to Tickets Step -->
    <div class="cv-form">
      <div class="cv-form__buttons">
        <div class="back-button btn">
          <router-link @click="setPrevStep" :to="{ name: 'Tickets' }">
            <span>{{ $t("back_button_to_tickets") }}</span>
          </router-link>
        </div>
        <button type="submit" class="button-wrapper btn" :class="computedClass"  @click="checkout">
          <span>{{ $t("tickets_checkout") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CvTicketSummery from "../components/TicketSummery.vue";
import CvApprovalRequiredInfoBox from "../components/ApprovalRequiredInfoBox.vue";

export default {
  components: {
    CvTicketSummery,
    CvApprovalRequiredInfoBox,
  },
  computed: {
    billingData() {
      return this.$store.getters["ticket/billingData"];
    },
    selectedTicketsPayable() {
      return this.$store.getters["ticket/selectedItemsPayable"];
    },
    selectedItemsApprovalRequired() {
      return this.$store.getters["ticket/selectedItemsApprovalRequired"];
    },
    selectedItemsConfirmed() {
      return this.$store.getters["ticket/selectedItemsConfirmed"];
    },
    selectedItems() {
      return this.$store.getters["ticket/selectedItems"];
    },
    computedClass() {
      let className = "disabled";
      if (this.selectedItems.length > 0) {
        className = "active";
      }
      return className;
    },
  },
  data: () => ({
    contact: null,
  }),
  methods: {
    // Set prev step by clicking on back button
    setPrevStep() {
      this.$store.commit("step", 4);
    },
    checkout() {
      this.$store.dispatch("ticket/checkout");
    },
  },
};
</script>

<style lang="scss">
.cv-basket {
  .cv-style--highlight {
    margin-bottom: 40px;
  }

  .billing-details {
    margin-bottom: 40px;

    div {
      font-size: 18px;
      line-height: 28px;
      font-family: "degular";
    }
  }

  .cv-headline {
    text-transform: capitalize;
    font-size: 32px;
    line-height: 34px;
    font-weight: 700;
    color: $color-body-1;
  }

  .cv-ticketsummery__item {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }

  .cv-field__label {
    margin-bottom: 10px;
  }

  .cv-form__buttons {
    margin-top: 40px;
  }

  .cv-form__buttons {
    width: 100% !important;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }
  .cv-form__buttons .btn {
    width: 100% !important;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      width: 50% !important;
    }
  }
  .cv-form__buttons .back-button {
    order: 2;
    margin-top: 1rem;

    @media (min-width: 768px) {
      margin-top: 0;
      order: 1;
    }
  }
  .cv-form__buttons .button-wrapper {
    @extend %button;
    width: auto !important;
    order: 1;

    @media (min-width: 768px) {
      width: 50% !important;
      order: 2;
    }
  }
  .cv-form__buttons .button-wrapper.disabled {
    background-color: #b4b8b8 !important;
    color: #fff !important;
    pointer-events: none;
  }
}
</style>
