<template>
  <transition
    name="cv-expand"
    @after-enter="afterEnter"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {
    afterEnter(element) {
      element.style.height = `auto`;
    },
    enter(element) {
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.position = `absolute`;
      element.style.visibility = `hidden`;
      element.style.height = `auto`;
      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;
      setTimeout(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;
      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;
      setTimeout(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style lang="scss">
.cv-expand-enter-active,
.cv-expand-leave-active {
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}
.cv-expand-enter-from,
.cv-expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
