<template>
  <div class="cv-start-ticket cv-ticket--active" :class="classes">
    <div class="cv-ticket__head">
      <div class="first-row">
        <div class="icon-name-wrapper">
          <div class="cv-ticket__icon">
            <span class="bh-font-ticket"></span>
          </div>
          <h4 class="cv-ticket__name">
            {{ formattedName }}
          </h4>
        </div>
        <div class="cv-price__value">
          <cv-price class="cv-ticket__price" :price="link.price" />
        </div>
      </div>
      <div class="second-row">
        <div class="cv-ticket__content">
            <cv-date :validFrom="link.validfrom" :validTo="link.validto"></cv-date>
        </div>
        <div class="cv-ticket__tools">
          <button
            v-if="!isSelectedCode"
            class="cv-ticket__button cv-button"
            @click="selectTicket(link.externalcode, link.ticket)"
          >
            {{ $t("ticket_select") }}
          </button>
          <button
            v-else
            class="cv-ticket__button cv-ticket__button--selected cv-button"
            @click="unselectTicket(link.externalcode, link.ticket)"
          >
            {{ $t("ticket_unselect") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CvPrice from "./Price.vue";
import CvDate from "./Date.vue";

export default {
  props: {
    link: Object,
  },
  components: {
    CvPrice,
    CvDate,
  },
  computed: {
    isSelectedCode() {
      return this.$store.getters["isSelectedCode"](this.link.externalcode);
    },
    classes() {
      let classes;
      if (this.isSelectedCode) {
        classes = "cv-ticket--selected";
      }
      return classes;
    },
    formattedName() {
      const name = this.link[
        "ticket@OData.Community.Display.V1.FormattedValue"
      ];

      return name.split("- Participant")[0];
    },
  },
  methods: {
    selectTicket(ticketCode, ticket) {
      this.$store.dispatch("selectTicketCode", {
        code: ticketCode,
        ticket: ticket,
      });
    },
    unselectTicket(ticketCode, ticket) {
      this.$store.dispatch("unselectTicketCode", {
        code: ticketCode,
        ticket: ticket,
      });
    },
  },
};
</script>

<style lang="scss">
.cv-start-ticket {
  &.cv-ticket--selected {
    background: black;
    color: white;

    .cv-ticket__head,
    .cv-ticket__icon,
    .cv-ticket__content,
    .cv-ticket__tools {
      background: black;
      color: white;

      .cv-ticket__name,
      .cv-price__value,
      .cv-price__value small,
      .cv-ticket-state__label {
        color: white;
      }
    }

    .label-approved {
      color: white;
    }

    .cv-ticket__button--selected {
      border: 1px solid white;
      background-color: transparent;
      color: white;
      display: flex;

      &::before {
        content: "";
        background-image: url("../assets/sass/ic_checkmark.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        height: 24px;
        width: 24px;
        margin-right: 16px;
        display: block;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }

      &:hover {
        background-color: transparent;

        &::after {
          margin-left: 22px;
        }
      }
    }
    .cv-price__percentage {
      color: $color-white;
    }
  }

  .cv-price__value {
    white-space: nowrap;
  }
}
</style>
