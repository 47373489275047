<template>
  <div role="group" :aria-labelledby="id" tabindex="0" :title="label" class="cv-field cv-field-radio">
    <div class="cv-field-radio__options">
      <div
        class="cv-field-radio__option"
        v-for="option in options"
        :key="option.value"
      >
        <div
          class="cv-field-radio__input"
          v-if="option.value == 'sepa' && showSepaData"
        >
          <input
            :id="name + option.value"
            type="radio"
            :value="option.value"
            :checked="option.value == modelValue"
            :name="name"
            :required="required"
            @input="onChanged"
            @blur="onBlur"
            @focus="onFocus"
          />
          <label
            class="cv-field-radio__input-faker"
            :for="name + option.value"
          ></label>
        </div>
        <label class="cv-field-radio__input" v-else-if="option.value !== 'sepa'">
          
          <input
            :id="name + option.value"
            type="radio"
            :value="option.value"
            :checked="option.value == modelValue"
            :name="name"
            :required="required"
            @input="onChanged"
            @blur="onBlur"
            @focus="onFocus"
          />

          <span>{{ option.name }}</span>
        </label>
        <div
          v-if="option.value == 'sepa' && showSepaData"
          class="cv-field-radio__input-label"
        >
          {{ option.name }}
        </div>
        <div
          v-else-if="option.value !== 'sepa'"
          class="cv-field-radio__input-label"
        >
          
        </div>
      </div>
    </div>
    <span :id="id" v-if="label" class="cv-field__label">
      {{ label }}<template v-if="required"> <span aria-hidden="true"> *</span></template>
    </span>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import { reportValidity, uuid } from "../../utils";

export default {
  props: {
    modelValue: [Number, String],
    label: String,
    options: Array,
    required: {
      type: Boolean,
      default: false,
    },
    showSepaData: Boolean,
  },
  emits: ["update:modelValue"],
  data: () => ({
    name: "",
    blurred: false,
    dirty: false,
    id: null,
    hasValidatedOnBlur: false,
  }),
  mounted() {
    this.id = uuid();
  },
  methods: {
    onChanged(e) {
      this.$emit("update:modelValue", e.currentTarget.value);

      this.dirty = true;

      if (this.blurred) {
        reportValidity(e.target);
      }
    },

    onBlur(e) {
      this.blurred = true;

      if (this.dirty && !this.hasValidatedOnBlur) {
        reportValidity(e.target);
        this.hasValidatedOnBlur = true;
      }
    },

    onFocus() {
      console.log("FOCUS");
    }
  },
  beforeMount() {
    this.name = uniqueId("radio_");
  },
};
</script>

<style lang="scss">
.cv-field.cv-field-radio {
  margin-bottom: 40px;

  .cv-field__label {
    margin-bottom: 10px;
  }
}
.cv-field-radio__options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.cv-field-radio__option {
  display: flex;
  align-items: center;
  margin-bottom: unit(0.5);
  margin-right: unit(1.5);
}
.cv-field-radio__input {
  position: relative;
  display: flex;
  gap: 8px;

  span {
    font-size: 16px;
    line-height: 18.58px;
  }

  input {
    appearance: none;
    background-color: #fff;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 21px;
    border-radius: 100%;
    border: 2px solid black;
    cursor: pointer;
    transition: all 0.4s;

    &::before {
      content: "";
      display: block;
      height: 11px;
      width: 11px;
      border-radius: 100%;
      background: transparent;
      transition: all 0.4s;
    }

    &:checked::before {
        background: black;
    }

    &:focus::before {
      border: 2px solid black;
    }
  }
}
.cv-field-radio__input-faker {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 100%;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.4s;

  &:before {
    content: "";
    display: block;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: transparent;
    transition: all 0.4s;
  }
}
.cv-field-radio__input-label {
  margin-left: unit(1);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.CardBrandIcon-container {
  display: none;
}

.cv-formbox .cv-form__buttons {
  width: fit-content;
}
.cv-checkout-payment .cv-form__fields {
  margin-bottom: 24px;
}
</style>
