<template>
  <div class="cv-invitations">
    <h1 class="cv-text--intro cv-margin">
      {{ $t("overview_invitations") }}
    </h1>

    <!-- Open invitations -->
    <header class="cv-open-invitations">
      <h2>{{ $t("open_invitations") }}</h2>
    </header>

    <div
      v-if="openInvitations.length > 0"
      class="cv-open-invitations-container"
    >
      <div class="cv-upcoming-sessions-tickets">
        <div
          v-for="session in openInvitations"
          :key="session.efa_sessioninvitationid"
          class="cv-invitations-ticket"
        >
          <cv-invitation :invitation="session"></cv-invitation>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="no-data">{{ $t("no_open_invitations") }}</p>
    </div>

    <!-- Upcoming sessions -->
    <div v-if="upcomingSessions.length > 0" class="cv-upcoming-sessions">
      <div
        :class="{
          'cv-upcoming-sessions-toggler': true,
          'cv-upcoming-sessions-toggler-active': additionalExpanded,
        }"
        v-on:click="additionalExpanded = !additionalExpanded"
      >
        <div class="cv-headline">
          <div
            class="plus-minus-toggle"
            :class="{ 'close-icon': additionalExpanded }"
          ></div>
          <h3>{{ $t("upcoming_sessions") }}</h3>
        </div>
      </div>
      <div
        :class="{
          'cv-redeem-content': true,
          'cv-redeem-visible': additionalExpanded,
        }"
      >
        <div class="cv-upcoming-sessions-tickets">
          <div
            v-for="session in upcomingSessions"
            :key="session.efa_sessioninvitationid"
            class="cv-invitations-ticket"
          >
            <cv-invitation :invitation="session"></cv-invitation>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <p class="no-data">{{ $t("no_upcoming_sessions") }}</p>
    </div>
  </div>
</template>

<script>
import CvInvitation from "../components/Invitation.vue";

export default {
  data: () => ({
    additionalExpanded: true,
  }),
  methods: {
    loadData() {
      this.$store.dispatch("invitationSessions");
      this.$store.dispatch("sessionWaitlist");
    }
  },
  mounted() {
    this.loadData();
    
    window.addEventListener("focus", this.loadData);
  },
  unmounted() {
    window.removeEventListener("focus", this.loadData);
  },
  components: {
    CvInvitation,
  },
  computed: {
    openInvitations() {
      const openInvitations = this.invitationSessions.filter(
        (session) => {
            if (Array.isArray(session.efa_sessionwaitlist_sessioninvitation_efa_ses) && session.efa_sessionwaitlist_sessioninvitation_efa_ses.length > 0) {
              return false;
            }

            return session.statuscode == 221120002 || session.statuscode === 221120005
        }
          
      );
      return openInvitations;
    },
    upcomingSessions() {
      const upcomingSessions = this.invitationSessions.filter(
        (session) => {
          if (Array.isArray(session.efa_sessionwaitlist_sessioninvitation_efa_ses) && session.efa_sessionwaitlist_sessioninvitation_efa_ses.length > 0) {
            return true;
          }

          return session.statuscode == 221120003 || session.statuscode == 221120004
        }
          
      );
      return upcomingSessions;
    },
    invitationSessions() {
      return this.$store.getters["invitationSessions"];
    },
  },
};
</script>

<style lang="scss">
.cv-invitations {
  .cv-open-invitations {
    h2 {
      color: $color-headings-dark;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      display: block;
      font-weight: 700;
      font-family: "degular";
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 32px;
      }
    }
  }

  .cv-upcoming-sessions {
    margin-top: 40px;

    .cv-upcoming-sessions-toggler {
      border-bottom: 1px solid #000;
      padding-bottom: 24px;

      .cv-headline {
        display: flex;
        cursor: pointer;
        margin-bottom: 0;
      }
    }

    .plus-minus-toggle {
      position: relative;
      width: 32px;
      height: 32px;
      margin-right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        transition: all 0.3s ease;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color-yearly-accent;
        display: inline-block;
      }

      &:after {
        content: "";
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: $color-yearly-accent;
        display: inline-block;
      }

      &.close-icon {
        &:after {
          margin-top: 0px;
          width: 0;
          height: 0;
        }
      }
    }
  }

  .cv-invitations-ticket {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #000000;
    font-family: "degular";
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
      padding: 24px 32px 32px 32px;
    }
  }

  .no-data {
    font-family: "degular";
    padding-bottom: 24px;
    font-size: 18px;
    line-height: 28px;
    color: #303139;
  }
}
</style>
