<template>
  <div class="cv-field cv-field-select">
    <div class="cv-field-select__wrapper">
      <select :id="id" :title="label" :required="required" :value="modelValue" @input="onChanged" @blur="onBlur">
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
      <div class="cv-field-select__icon bh-font-arrow-simple-down"></div>
    </div>
    <label :for="id" class="cv-field__label"
      >{{ label }}<template v-if="required"> <span aria-hidden="true"> *</span></template></label
    >
  </div>
</template>

<script>
import { reportValidity, uuid } from "../../utils";

export default {
  data() {
    return {
      blurred: false,
      dirty: false,
      id: null,
      hasValidatedOnBlur: false,
    }
  },
  mounted() {
    this.id = uuid();
  },
  props: {
    modelValue: [Number, String],
    label: String,
    options: Array,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onChanged(e) {
      this.$emit("update:modelValue", e.currentTarget.value);

      this.dirty = true;

      if (this.blurred) {
        reportValidity(e.target);
      }
    },

    onBlur(e) {
      this.blurred = true;

      if (this.dirty && !this.hasValidatedOnBlur) {
        reportValidity(e.target);
        this.hasValidatedOnBlur = true;
      }
    }
  },
};
</script>

<style lang="scss">
.cv-field-select__wrapper {
  position: relative;

  select {
    @extend %basic-field;
    appearance: none;
    border: 1px solid $color-grey-500;
    font-family: "degular";
  }
}
.cv-field-select__icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: $color-black;
  pointer-events: none;
  font-size: unit(1.5);
}
</style>
