<template>
  <li class="cv-contactlist-item">
    <div class="cv-contactlist-item__address-container">
      <div class="cv-contactlist-item__icon">ICO</div>
      <div class="cv-contactlist-item__body">
        <p class="cv-contactlist-item__name">
          {{ contact.firstname }} {{ contact.lastname }}
        </p>
        <div class="cv-contactlist-item__address-box">
          <template v-if="hasAddress">
            <div class="cv-contactlist-item__address-badge">
              <span>Adresse</span>
              <span class="cv-badge">{{ preferredAddress }}</span>
            </div>
            <div class="cv-contactlist-item__address">
              {{ address.street }}<br />
              {{ address.zip }} {{ address.city }}<br />
              {{ address.country }}
            </div>
          </template>
          <template v-else>
            <div>Postadresse: {{ preferredAddress }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="cv-contactlist-item__tools">
      <div class="cv-button">wählen</div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    contact: Object,
  },
  computed: {
    hasAddress() {
      return this.contact.new_bevorzugtepostadresse != 100000000;
    },
    preferredAddress() {
      return this.contact[
        "new_bevorzugtepostadresse@OData.Community.Display.V1.FormattedValue"
      ];
    },
    address() {
      if (this.contact.new_bevorzugtepostadresse == 100000001) {
        return {
          street: this.contact.address2_line1,
          zip: this.contact.address2_postalcode,
          city: this.contact.address2_city,
          country: this.contact[
            "_new_landgeschftlich_value@OData.Community.Display.V1.FormattedValue"
          ],
        };
      } else {
        return {
          street: this.contact.address3_line1,
          zip: this.contact.address3_postalcode,
          city: this.contact.address3_city,
          country: this.contact[
            "_new_landprivat_value@OData.Community.Display.V1.FormattedValue"
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss">
.cv-contactlist-item {
  position: relative;
  background: $color-gold-light;
  padding: unit(1);
  margin-bottom: unit(1);
  border-radius: 5px;
  overflow: hidden;

  display: flex;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background: $color-gold;
  }
}

.cv-contactlist-item__body {
  flex-grow: 1;
  padding: 0 unit(1);
}

.cv-contactlist-item__address {
  font-size: 0.8em;
}

.cv-contactlist-item__tools {
  align-self: flex-end;
}

.cv-contactlist-item__address-badge {
  display: flex;
  align-items: center;

  span:first-child {
    padding-right: unit(1);
  }
}
</style>
