<template>
  <div class="cv-ticketsummery">
    <ul class="cv-ticketsummery__list">
      <li
        class="cv-ticketsummery__item"
        v-for="ticket in tickets"
        :key="ticket.id"
      >
        <div class="cv-ticketname-status">
          <div>
            {{ ticket.efa_name }}
          </div>
          <div v-if="showStatus" class="cv-badge">
            {{ statusMessage(ticket) }}
          </div>
        </div>
        <div v-if="showDeleteButton" class="cv-remove-and-price">
          <button :title="`Remove ${ticket.efa_name} ticket`" class="delete" @click="unselect(ticket)">
            <img src="../assets/sass/stepper.svg" />
          </button>
          <div class="price">
            {{ ticketPrice(ticket["efa_totalprice"]) }}
          </div>
        </div>
        <div v-else class="price-only">
          <div class="price">
            {{ ticketPrice(ticket["efa_totalprice"]) }}
          </div>
        </div>
      </li>
    </ul>
    <div v-if="price" class="price-wrapper">
      <section class="total-price-wrapper">
        <span class="itermediate-price-label">{{ $t("total_net_price_label") }}</span>
        <span class="price">
          {{ formatPrice(subtotal) }}
        </span>
      </section>
      
      <section class="total-price-wrapper">
        <span class="itermediate-price-label">{{ $t("vat_label") }}</span>
        <span class="price">
          {{ formatPrice(vat) }}
        </span>
      </section>
      
      <section class="total-price-wrapper">
        <div class="total-price-label">{{ $t("total_price_label") }}</div>
        <div class="cv-ticketsummery__price">
          {{ formatPrice(totalPrice) }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { VAT } from '../config';

export default {
  props: {
    tickets: Array,
    price: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: Boolean,
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subtotal() {
      return this.tickets.reduce((acc, item) => acc + item.efa_totalprice, 0);
    },

    vat() {
      return this.subtotal * VAT;
    },

    totalPrice() {
      return this.subtotal + this.vat;
    }
  },
  methods: {
    formatPrice(price) {
      const formattedPrice = this.commify(price.toFixed(2));
      return "€ " + formattedPrice;
    },

    statusMessage(ticket) {
      return ticket["statuscode@OData.Community.Display.V1.FormattedValue"];
    },
    unselect(ticket) {
      this.$store.dispatch("ticket/unselectItem", ticket);
    },
    ticketPrice(price) {
      const formattedPrice = this.commify(price.toFixed(2));
      return "€ " + formattedPrice;
    },
    commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      let decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return (
        numberPart.replace(thousands, ".") +
        (decimalPart ? "," + decimalPart : "")
      );
    },
  },
};
</script>

<style lang="scss">
.cv-ticketsummery {
  color: $color-body-1;
  font-weight: normal;
  font-size: 24px;
  line-height: 31.85px;
  font-family: "degular";

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
}
.cv-ticketsummery__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cv-ticketsummery__item {
  display: flex;
  justify-content: space-between;
  padding: unit(1) 0;
  border-bottom: 1px solid #b4b8b8;
}
.cv-ticketsummery__price {
  padding: unit(1) 0;
  text-align: right;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
}

.cv-ticketname-status {
  display: flex;
}

.cv-ticketname-status .cv-badge {
  margin-left: 16px;
}

.price-only {
  margin-left: 2rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.cv-remove-and-price {
  display: flex;
  width: 9rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-left: 1rem;

  .delete {
    display: inline-flex;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 0;
    border: none;

    &:hover {
      transform: scale(1.1);
    }
  }

  .price {
    white-space: nowrap;
  }
}
.price-wrapper {
  margin-bottom: 80px;
}

.price {
  font-size: 18px;
}

.itermediate-price-label {
  font-size: 18px;
  line-height: 28px;
}

.total-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-price-label {
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}
</style>
