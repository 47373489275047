<template>
  <div
    class="cv-ticket__session cv-badge"
    :class="{ 'cv-ticket__session--active': isSelected }"
  >
    {{ session.msevtmgt_name }}
  </div>
</template>

<script>
export default {
  props: {
    ticket: Object,
    session: Object,
  },
  computed: {
    isSelected() {
      return this.$store.getters["ticket/isSessionSelected"](this.session);
    },
  },
};
</script>

<style lang="scss">
.cv-ticket__session {
  margin: 0 unit(0.5) unit(0.2) 0;

  &.cv-ticket__session--active {
    background: $color-green;
  }
}
</style>
