<template>
  <div class="cv-checkout-payment">
    <div class="cv-text--intro cv-margin">
      <h2 tabindex="0" class="cv-style--highlight">{{ $t("payment_headline") }}</h2>
      <div tabindex="0" aria-label="Billing Details" class="billing-details">
        <div v-if="billingData.address.company">
          {{ billingData.address.company }}
        </div>
        <div>{{ billingData.name }}</div>
        <!-- <div>{{ billingData.mail }}</div> -->
        <div>{{ billingData.address.street }}</div>
        <div>{{ billingData.address.zip }} {{ billingData.address.city }}</div>
        <div>{{ billingData.address.country }}</div>
        <div v-if="billingData.address.uid">
          {{ billingData.address.uid }}
        </div>
      </div>
    </div>

    <section v-if="confirmed.length">
      <h3 class="cv-headline">{{ $t("payment_confirmed_tickets") }}</h3>
      <cv-ticket-summery
        class="cv-margin"
        :tickets="confirmed"
        :price="false"
      />
    </section>

    <section v-if="approvals.length">
      <h3 class="cv-headline">{{ $t("needs_approval") }}</h3>
      <cv-ticket-summery
        class="cv-margin"
        :tickets="approvals"
        :price="false"
        :showStatus="true"
      />
    </section>

    <section v-if="tickets.length">
      <h3 class="cv-headline">{{ $t("payable") }}</h3>
      <cv-ticket-summery class="cv-margin" :tickets="tickets" />
    </section>

    <!-- Approval required Infobox  -->
    <cv-approval-required-info-box v-if="approvals.length" />

    <cv-radio-field
      v-model="paymentType"
      :required="true"
      :options="paymentTypeOptions"
      :label="$t('paymenttype_preferred')"
      :showSepaData="showSepaData"
    />
    <cv-stripe-card v-show="paymentType == 'card'" />
    <cv-stripe-sepa
      :showSepaData="showSepaData"
      v-show="paymentType == 'sepa'"
    />
  </div>
</template>

<script>
import CvRadioField from "../components/form/RadioField.vue";
import CvTicketSummery from "../components/TicketSummery.vue";
import CvStripeCard from "../components/stripe/Card.vue";
import CvStripeSepa from "../components/stripe/Sepa.vue";
import CvApprovalRequiredInfoBox from "../components/ApprovalRequiredInfoBox.vue";

export default {
  components: {
    CvRadioField,
    CvStripeCard,
    CvStripeSepa,
    CvTicketSummery,
    CvApprovalRequiredInfoBox,
  },
  computed: {
    billingData() {
      return this.$store.getters["ticket/billingData"];
    },
    tickets() {
      return this.$store.state.ticket.paymentData.approved;
    },
    approvals() {
      return this.$store.state.ticket.paymentData.approvalrequired;
    },
    confirmed() {
      return this.$store.state.ticket.paymentData.confirmed;
    },
  },
  data: () => ({
    contact: null,
    paymentType: "card",
    paymentTypeOptions: [
      {
        value: "card",
        name: "credit card",
      },
      {
        value: "sepa",
        name: "sepa direct debit",
      },
    ],
    showSepaData: true,
  }),
  methods: {
    checkIfSepaIsAvailable() {
      let currentDate = new Date();
      const sepaDeadline = new Date(this.$store.getters["ticket/sepaDeadline"]);
      setInterval(() => {
        currentDate = new Date();
        if (currentDate >= sepaDeadline) {
          this.showSepaData = false;
        } else {
          this.showSepaData = true;
        }
      }, 1000);
    },
  },
  mounted() {
    this.checkIfSepaIsAvailable();
  },
};
</script>

<style lang="scss">
.cv-checkout-payment {
  .cv-style--highlight {
    margin-bottom: 40px;
  }

  .billing-details {
    margin-bottom: 40px;

    div {
      font-size: 18px;
      line-height: 28px;
      font-family: "degular";
    }
  }

  .cv-headline {
    text-transform: capitalize;
    font-size: 32px;
    line-height: 34px;
    font-weight: 700;
    color: $color-body-1;
  }

  .cv-ticketsummery__item {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }

  .cv-field__label {
    margin-bottom: 10px;
  }

  .cv-form__buttons {
    margin-top: 40px;
  }
}
</style>
