import axios from "axios";
import { baseUrl } from "../../api";
import { useMock } from "../../utils";
import { PRONOUNS_OTHER } from "../../config";

const getMetadataOptionSets = async (token) => {
  if (process.env.NODE_ENV === "development" && useMock()) {
    const mock = await import("../../api/mocks/getmetadataoptionsets.json");
    return { data: { value: mock.value } };
  }

  return axios.get(baseUrl + "getmetadataoptionsets", {
    params: {
      t: token,
    },
  })
}

const getMetadataLookups = async (token) => {
  if (process.env.NODE_ENV === "development" && useMock()) {
    const mock = await import("../../api/mocks/getmetadatalookups.json");
    return { data: { value: mock.value } };
  }

  return axios.get(baseUrl + "getmetadatalookups", {
    params: {
      t: token,
    },
  })
}

const state = {
  loaded: false,
  gender: [],
  pronouns: [],
  languages: [],
  countries: [],
  positions: [],
  industries: [],
  trackinterest: [],
  fieldofactivity: [],
  preferredMailOptions: [
    {
      value: 100000001,
      name: "business contact",
    },
    {
      value: 100000000,
      name: "private contact",
    },
  ],
  preferredAddressOptions: [
    {
      value: 100000001,
      name: "business address",
    },
    {
      value: 100000002,
      name: "private address",
    },
  ],
  preferredPhoneOptions: [
    {
      value: 100000001,
      name: "business phone",
    },
    {
      value: 100000000,
      name: "private mobile",
    },
  ],
};

const mutations = {
  initialized: (state) => {
    state.loaded = true;
  },
  data: (state, data) => {
    Object.assign(state, data);
  },
};

const getters = {
  genderOptions: (state) => {
    return state.gender
      .filter((item) => item.langid == 1031)
      .map((item) => {
        return {
          value: item.attributevalue,
          name: item.value,
        };
      });
  },
  pronounsOptions: (state) => {
    return state.pronouns
      .map((item) => {
        return {
          value: item.attributevalue,
          name: item.value,
        };
      });
  },
  trackInterestOptions: (state) => {
    return state.trackinterest.map((item) => {
      return {
        value: item.attributevalue,
        name: item.value,
      };
    });
  },
  preferredMailOptions: (state) => {
    return state.preferredMailOptions;
  },
  preferredAddressOptions: (state) => {
    return state.preferredAddressOptions;
  },
  preferredPhoneOptions: (state) => {
    return state.preferredPhoneOptions;
  },
  languageOptions: (state) => {
    return state.languages.map((item) => {
      return {
        value: item.navax_languageid,
        name: item.navax_name,
      };
    });
  },
  nationalityOptions: (state) => {
    return state.countries.map((item) => {
      return {
        value: item.navax_countryid,
        name: item.new_nameenglisch,
      };
    });
  },
  countryOptions: (state) => {
    return state.countries.map((item) => {
      return {
        value: item.navax_countryid,
        name: item.new_nameenglisch,
      };
    });
  },
  positionOptions: (state) => {
    //TODO language + gender
    return state.positions.map((item) => {
      return {
        value: item.new_positionefaid,
        name: item.new_nameenglisch,
      };
    });
  },
  fieldOfActivityOptions: (state) => {
    return state.fieldofactivity.map((item) => {
      return {
        value: item.efa_fieldofactivityid,
        name: item.efa_fieldofactivityengl,
      };
    });
  },
  industryOptions: (state) => {
    return state.industries.map((item) => {
      return {
        value: item.navax_industryid,
        name: item.new_nameenglisch,
      };
    });
  },
};

const actions = {
  load({ commit, dispatch, state, rootState }) {
    if (!state.loaded) {
      commit("loading", true, { root: true });
      commit("initialized");
      let token = rootState.tokenJWT;
      let getmetadataoptionsets = getMetadataOptionSets(token);
      let getmetadatalookups = getMetadataLookups(token);

      Promise.all([getmetadataoptionsets, getmetadatalookups])
        .then(([optionsets, datalookups]) => {
          commit("loading", false, { root: true });
          //TODO besteht optionsets wirklich nur aus gendercodes?
          let optionset = optionsets.data.value;

          let gender = optionset.filter((item) => {
            return item.attributename == "gendercode";
          });

          let trackinterest = optionset.filter((item) => {
            return item.attributename == "efa_trackinterest";
          });

          let pronouns = optionset.filter((item) => {
            return item.attributename == "efa_pronouns";
          });

          pronouns.sort((a, b) => {
            if (a.attributevalue === PRONOUNS_OTHER) {
              return 1;
            }

            if (b.attributevalue === PRONOUNS_OTHER) {
              return -1;
            }

            return a.attributevalue - b.attributevalue;
          })

          let metaData = Object.assign(
            {
              gender,
              trackinterest,
              pronouns,
            },
            datalookups.data.value
          );

          commit("data", metaData);
        })
        .catch(() => {
          commit("loading", false, { root: true });
          dispatch("error", "Verify contact failed.", { root: true });
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
