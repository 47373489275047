<template>
  <div class="cv-contact">
    <div class="cv-text--intro cv-margin">
      <p>{{ $t("user_intro_text") }}</p>
    </div>

    <!-- Infobox -->
    <div class="cv-infobox-toggler-wrapper">
      <div class="text-box">
        <h2 class="cv-infobox-headline">
          {{ $t("infobox_contact_headline") }}
          <img src="../assets/sass/ic_tickets.svg" />
        </h2>
        <p class="cv-infobox-intro">
          {{ $t("infobox_contact_intro") }}
        </p>
      </div>
      <!-- Hidden Infobox Content -->
      <div
        :class="{
          'cv-infobox-content': true,
          'cv-infobox-visible': additionalExpanded,
        }"
      >
        <transition name="cv-slide-fade">
          <div class="cv-content">
            <p>{{ $t("infobox_contact_content") }}</p>
            <ul>
              <li>{{ $t("infobox_contact_content_li_01") }}</li>
              <li>{{ $t("infobox_contact_content_li_02") }}</li>
              <li>{{ $t("infobox_contact_content_li_03") }}</li>
              <li>{{ $t("infobox_contact_content_li_04") }}</li>
            </ul>
          </div>
        </transition>
      </div>
      <!-- Infobox toggle button -->
      <div class="button-wrapper">
        <button
          :style="{ border: 'none' }"
          :class="{
            'cv-infobox-toggler': true,
            'cv-infobox-toggler-active': additionalExpanded,
          }"
          v-on:click="additionalExpanded = !additionalExpanded"
        >
          <span class="button-text">
            {{ additionalExpanded ? "Show less" : "Learn more" }}
          </span>
          <div
            :class="{
              'image-container': true,
              'open-box': additionalExpanded,
            }"
          >
            <img src="../assets/sass/ic_chevron_open_tab.svg" />
          </div>
        </button>
      </div>
    </div>

    <cv-form
      @submit="submitData"
      :backButton="true"
      :backButtonLabel="$t('back_button_cancel')"
      :backButtonMethod="clearData"
      backButtonRoute="Home"
    >
      <section class="cv-formbox cv-margin">
        <h3 class="cv-headline">{{ $t("user_headline_person") }}</h3>
        <p class="cv-description-about--details">
          {{ $t("user_text_email_infotext") }}
        </p>

        <div class="cv-form-columns">
          <cv-select-field
            class="cv-form-column"
            v-model="contact.efa_pronouns"
            :options="optionsPronouns"
            :required="true"
            :label="$t('user_pronouns')"
          />
          <cv-input-field
            v-if="contact.efa_pronouns === PRONOUNS_OTHER"
            class="cv-form-column"
            v-model="contact.efa_otherpronouns"
            :required="true"
            :label="$t('user_pronouns_other')"
          />
        </div>

        <section>
          <cv-checkbox-field
            v-model="contact.efa_mentionpronouns"
            :label="$t('user_show_pronouns')"
          />
        </section>

        <div class="cv-form-columns">
          <cv-input-field
            class="cv-form-column"
            v-model="contact.firstname"
            :required="true"
            :label="$t('user_firstname')"
          />
          <cv-input-field
            class="cv-form-column"
            v-model="contact.lastname"
            :required="true"
            :label="$t('user_lastname')"
          />
        </div>

        <cv-radio-field
          v-model="contact.new_bevorzugteemail"
          :required="true"
          :options="optionsMailPreferred"
          :label="$t('user_address_mail')"
        />
        <transition name="cv-fade" mode="out-in">
          <cv-input-field
            v-if="contact.new_bevorzugteemail == 100000000"
            v-model="contact.emailaddress3"
            type="email"
            :required="true"
            :label="$t('user_mail_private')"
          />
          <cv-input-field
            v-else-if="contact.new_bevorzugteemail == 100000001"
            v-model="contact.emailaddress2"
            type="email"
            :required="true"
            :label="$t('user_mail_business')"
          />
        </transition>

        <transition name="cv-fade" mode="out-in">
          <div
            class="cv-form-columns"
            v-if="contact.new_bevorzugteemail == 100000000"
          >
            <cv-input-field
              class="cv-form-column"
              v-model="contact.telephone2"
              :required="false"
              pattern="^\+[0-9 ]*$"
              placeholder="+43 111 222"
              :label="$t('user_telephone')"
            />
            <cv-input-field
              class="cv-form-column"
              v-model="contact.telephone3"
              :required="true"
              pattern="^\+[0-9 ]*$"
              placeholder="+43 111 222"
              :label="$t('user_mobilephone')"
            />
          </div>
          <div
            class="cv-form-columns"
            v-else-if="contact.new_bevorzugteemail == 100000001"
          >
            <cv-input-field
              class="cv-form-column"
              v-model="contact.telephone1"
              :required="false"
              pattern="^\+[0-9 ]*$"
              placeholder="+43 111 222"
              :label="$t('user_telephone_business')"
            />
            <cv-input-field
              class="cv-form-column"
              v-model="contact.mobilephone"
              :required="true"
              pattern="^\+[0-9 ]*$"
              placeholder="+43 111 222"
              :label="$t('user_mobilephone_business')"
            />
          </div>
        </transition>
        <div class="cv-form-columns">
          <cv-input-field
            class="cv-form-column"
            v-model="contact.birthdate"
            type="date"
            :required="true"
            :label="$t('user_birthdate')"
          />
          <!-- pattern="([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))" -->
          <cv-select-field
            class="cv-form-column"
            v-model="contact.gendercode"
            :options="optionsGender"
            :required="true"
            :label="$t('user_gender')"
          />
        </div>
        <div class="cv-form-columns">
          <cv-select-field
            class="cv-form-column"
            v-model="contact._navax_language_value"
            :options="optionsLanguage"
            :required="true"
            :label="$t('user_language')"
          />
          <cv-select-field
            class="cv-form-column"
            v-model="contact._new_nationalitt_value"
            :options="optionsNationality"
            :required="true"
            :label="$t('user_nationality')"
          />
        </div>
        <!--
        <cv-radio-field
            v-model="contact.new_bevorzugtestelefon"
            :required="true"
            :options="optionsPhonePreferred"
        />
        -->
        <!-- <template v-if="contact.new_xnetid">
          <cv-input-field
            v-model="contact.efa_emergencycontact"
            :required="true"
            :label="$t('user_emergencycontact')"
          />
        </template> -->
      </section>

      <section class="cv-formbox cv-margin">
        <h3 class="cv-headline">{{ $t("user_headline_company") }}</h3>
        <p class="cv-description-text--address">
          {{ $t("user_text_why_company") }}
        </p>
        <cv-input-field
          v-model="contact.new_firmennameaccess"
          :required="false"
          :label="$t('user_company')"
        />

        <div class="cv-form-columns">
          <cv-select-field
            class="cv-form-column full-width-select"
            v-model="contact._new_positionefa_value"
            :options="optionsPosition"
            :required="false"
            :label="$t('user_company_position')"
          />
          <cv-input-field
            class="cv-form-column"
            v-if="
              contact._new_positionefa_value ==
              '019ff9bb-f9cb-ea11-a812-000d3a294639'
            "
            v-model="contact.efa_otherposition"
            :required="true"
            :label="$t('user_other_position')"
          />
        </div>

        <div class="cv-form-columns">
          <cv-select-field
            class="cv-form-column full-width-select"
            v-model="contact._efa_fieldofactivity_value"
            :options="optionsFieldOfActivity"
            :required="false"
            :label="$t('user_company_field_of_activity')"
          />
          <cv-input-field
            class="cv-form-column"
            v-if="
              contact._efa_fieldofactivity_value ==
              '057e8b7d-04b3-eb11-8237-002248828ba2'
            "
            v-model="contact.efa_otherfieldofactivity"
            :required="true"
            :label="$t('user_other_field_of_activity')"
          />
        </div>

        <div class="cv-form-columns">
          <cv-select-field
            class="cv-form-column full-width-select"
            v-model="contact._new_branche_value"
            :options="optionsIndustry"
            :required="optionsIndustry.length > 0 ? true : false"
            :label="$t('user_company_industry')"
          />
          <cv-input-field
            class="cv-form-column"
            v-if="
              contact._new_branche_value ==
              '9c2f7a96-f9cb-ea11-a812-000d3a294639'
            "
            v-model="contact.efa_otherindustry"
            :required="true"
            :label="$t('user_other_field_of_industry')"
          />
        </div>
      </section>

      <section class="cv-formbox cv-margin">
        <h3 class="cv-headline">{{ $t("user_headline_address") }}</h3>
        <p class="cv-description-text--address">
          {{ $t("user_address_used_for_billing") }}
        </p>
        <cv-radio-field
          v-model="contact.new_bevorzugtepostadresse"
          :required="true"
          :options="optionsAddressPreferred"
          :label="$t('user_address_preferred')"
        />
        <transition name="cv-fade" mode="out-in">
          <div
            v-if="contact.new_bevorzugtepostadresse == 100000002"
            class="cv-formbox-div"
          >
            <cv-input-field
              v-model="contact.address3_line1"
              :required="true"
              :label="$t('user_address_street')"
            />
            <div class="cv-form-columns">
              <cv-input-field
                class="cv-form-column"
                v-model="contact.address3_postalcode"
                :required="true"
                :label="$t('user_address_zip')"
              />
              <cv-input-field
                class="cv-form-column"
                v-model="contact.address3_city"
                :required="true"
                :label="$t('user_address_city')"
              />
            </div>
            <cv-select-field
              v-model="contact._new_landprivat_value"
              :options="optionsCountry"
              :required="true"
              :label="$t('user_address_country')"
            />
          </div>
          <div
            v-else-if="contact.new_bevorzugtepostadresse == 100000001"
            class="cv-formbox-div"
          >
            <cv-input-field
              v-model="contact.address2_line1"
              :required="true"
              :label="$t('user_address_street')"
            />

            <div class="cv-form-columns">
              <cv-input-field
                class="cv-form-column"
                v-model="contact.address2_postalcode"
                :required="true"
                :label="$t('user_address_zip')"
              />
              <cv-input-field
                class="cv-form-column"
                v-model="contact.address2_city"
                :required="true"
                :label="$t('user_address_city')"
              />
            </div>
            
            <cv-select-field
              v-model="contact._new_landgeschftlich_value"
              :options="optionsCountry"
              :required="true"
              :label="$t('user_address_country')"
            />

            <cv-input-field
              v-model="contact.efa_vatnumber_business"
              :required="false"
              :label="$t('user_address_uid')"
            />
          </div>
        </transition>
      </section>

      <section class="cv-formbox cv-margin">
        <h3 class="cv-headline">{{ $t("user_headline_payment") }}</h3>

        <cv-infobox class="cv-margin">
          {{ $t("paymentWithCardsOnly") }}
        </cv-infobox>

        <cv-checkbox-field
          v-model="contact.efa_billingaddressdifferent"
          :label="$t('user_invoice_address')"
        />
        <cv-transition-height>
          <div
            v-if="contact.efa_billingaddressdifferent"
            class="cv-formbox-div"
          >
            <div class="cv-form-columns">
              <cv-input-field
                class="cv-form-column"
                v-model="contact.efa_invoicecompany"
                :required="true"
                :label="$t('user_address_company')"
              />
              <cv-input-field
                class="cv-form-column"
                v-model="contact.new_uid_nummer"
                :required="false"
                :label="$t('user_address_uid')"
              />
            </div>
            <cv-input-field
              v-model="contact.efa_invoiceemail"
              type="email"
              :required="false"
              :label="$t('user_mail')"
            />
            <cv-input-field
              v-model="contact.efa_invoicestreet"
              :required="false"
              :label="$t('user_address_street')"
            />
            <div class="cv-form-columns">
              <cv-input-field
                class="cv-form-column"
                v-model="contact.efa_invoicepostalcode"
                :required="false"
                :label="$t('user_address_zip')"
              />
              <cv-input-field
                class="cv-form-column"
                v-model="contact.efa_invoicecity"
                :required="false"
                :label="$t('user_address_city')"
              />
            </div>
            <cv-select-field
              v-model="contact._efa_invoicecountry_value"
              :options="optionsCountry"
              :required="true"
              :label="$t('user_address_country')"
            />
          </div>
        </cv-transition-height>
      </section>

      <!--
      <div class="cv-formbox cv-margin">
        <cv-select-field
          class="cv-form-column"
          v-model="contact.efa_trackinterest" 
          :options="optionsTrackInterest"
          :required="true"
          :label="$t('track_interest')"
        />
      </div>
      -->

      <div class="cv-contact__checkboxes">
        <h3 class="cv-headline">{{ $t("user_dsgvo_headline") }}</h3>
        <cv-infobox class="cv-margin">
          For preperation, facilitation and evaluation, we will electronically
          process your data. We will occasionally share with you information and
          invitations to similar events in the future. Please note that photos
          and videos (live streams or recordings) will be taken during the
          event. To exercise your right to object, send us an e-mail to
          datenschutz@alpbach.org.
        </cv-infobox>
        <!--
        <cv-checkbox-field v-model="contact.bulkemail">
          {{ $t("user_donotbulkemail") }}
        </cv-checkbox-field>
        -->
        <cv-checkbox-field v-model="contact.new_agbakzeptiert" :required="true">
          I hereby declare to have read and accepted the
          <a
            href="https://www.alpbach.org/general-terms-association"
            target="_blank"
            >General Terms and Conditions</a
          >
          of the European Forum Alpbach Non-Profit Association.
        </cv-checkbox-field>
        <cv-checkbox-field v-model="contact.new_datenschutzok" :required="true">
          I have read the
          <a
            href="https://www.alpbach.org/en/data-protection-association/"
            target="_blank"
            >Privacy Policy</a
          >
          of the European Forum Alpbach.
        </cv-checkbox-field>
        <cv-checkbox-field
          v-model="contact.efa_codeofconductaccepted"
          :required="true"
        >
          I have read the
          <a href="https://www.alpbach.org/code-of-conduct" target="_blank"
            >Code of Conduct</a
          >
          of the European Forum Alpbach.
        </cv-checkbox-field>
        <!--
        <cv-checkbox-field
          v-model="contact.efa_codeofconductaccepted"
          :required="true"
        >
          {{ $t("usercode_of_conduct") }}
        </cv-checkbox-field>
        -->
        <cv-checkbox-field
          v-model="contact.efa_attendancedatatransfer"
          :required="false"
        >
          {{ $t("efa_attendancedatatransfer") }}
        </cv-checkbox-field>

        <!-- NEW -->
        <!-- <cv-checkbox-field> -->
        <small class="swapcard-infotext">
          * When you register, an account will be created with all the
          information that you entered. This account will allow you to access to
          the platform and the attendees' list to network before, during and
          after the event. For further information on your data processing and
          your rights, please refer to Swapcard's protection of data policy:
          https://www.swapcard.com/gdpr or send an email to event@swapcard.com
        </small>
        <!-- </cv-checkbox-field> -->

        <!-- <template v-if="contact.new_xnetid">
          <cv-checkbox-field
            v-model="contact.efa_scholarship_terms_accepted"
            :required="true"
          >
            {{ $t("user_scholarship_terms") }}
          </cv-checkbox-field>
        </template> -->
      </div>
    </cv-form>
  </div>
</template>

<script>
import CvForm from "../components/form/Form.vue";
import CvInputField from "../components/form/InputField.vue";
import CvSelectField from "../components/form/SelectField.vue";
import CvRadioField from "../components/form/RadioField.vue";
import CvCheckboxField from "../components/form/CheckboxField.vue";
import CvInfobox from "../components/Infobox.vue";
import CvTransitionHeight from "../components/TransitionHeight.vue";
import { mapGetters } from "vuex";
import { PRONOUNS_OTHER } from "../config";

export default {
  components: {
    CvForm,
    CvInputField,
    CvSelectField,
    CvRadioField,
    CvCheckboxField,
    CvInfobox,
    CvTransitionHeight,
  },

  computed: {
    PRONOUNS_OTHER: () => PRONOUNS_OTHER.toString(),
    ...mapGetters({
      optionsMailPreferred: "metadata/preferredMailOptions",
      optionsAddressPreferred: "metadata/preferredAddressOptions",
      optionsPhonePreferred: "metadata/preferredPhoneOptions",
      optionsGender: "metadata/genderOptions",
      optionsPronouns: "metadata/pronounsOptions",
      optionsTrackInterest: "metadata/trackInterestOptions",
      optionsLanguage: "metadata/languageOptions",
      optionsCountry: "metadata/countryOptions",
      optionsNationality: "metadata/nationalityOptions",
      optionsPosition: "metadata/positionOptions",
      optionsIndustry: "metadata/industryOptions",
      optionsFieldOfActivity: "metadata/fieldOfActivityOptions",
    }),
  },
  data: () => ({
    contact: null,
    invoiceAddress: false,
    additionalExpanded: false,
  }),
  methods: {
    submitData() {
      this.$store.commit("step", 4);
      var re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

      if (this.contact.birthdate.includes("/")) {
        this.contact.birthdate = this.contact.birthdate.replace("/", "-");
      }

      if (re.test(this.contact.birthdate)) {
        this.$store.dispatch("updateContact", this.contact);
      } else {
        alert("Please check the field birthdate");
      }
    },
    clearData() {
      this.$store.commit("step", 1);
    },
  },
  beforeMount() {
    this.$store.dispatch("metadata/load");
    //TODO prüfen ob das irgendwas überschreiben könnte, z.B. loading state
    this.contact = Object.assign({ efa_attendancedatatransfer: true }, this.$store.state.contact);

    this.contact.bulkemail = !this.contact.donotbulkemail;
  },
};
</script>

<style lang="scss">
.cv-contact__checkboxes {
  padding: 0 unit(2);
  margin-bottom: unit(4);
}

.cv-contact .cv-form__buttons {
  width: 100% !important;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.cv-contact .cv-form__buttons .btn {
  width: 100% !important;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 50% !important;
  }
}
.cv-contact .cv-form__buttons .back-button {
  margin-top: 1rem;

  @media (min-width: 768px) {
    margin-right: 12px;
    margin-top: 0;
  }
}
.cv-contact .cv-form__buttons .button-wrapper {
  width: auto !important;

  @media (min-width: 768px) {
    margin-left: 12px;
    width: 50% !important;
  }
}

.cv-contact {
  .cv-form__buttons {
    .button-wrapper {
      position: relative;
      background-color: black;
      color: white;
      border-radius: 2px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 32px;
      padding-right: 32px;
      display: flex;
      justify-content: center;

      &:hover,
      &:focus {
        color: white;

        .input {
          background-color: black;
        }
      }

      &:disabled,
      &.disabled {
        color: $color-grey-500;
      }

      &::after {
        content: "";
        background-image: url("../assets/sass/ic_arrow_right.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        height: 24px;
        width: 40px;
        display: block;
        margin-left: 16px;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        @media (min-width: 768px) {
          width: 24px;
        }
      }

      &:hover {
        &::after {
          margin-left: 22px;
        }
      }

      span {
        font-family: "degular";
        font-weight: 600;
        font-size: 18px;
        line-height: 23.89px;
      }

      input {
        position: absolute;
        inset: 0;
        background-color: transparent;
        font-size: 0;
      }
    }
  }
}

.cv-fade-enter-active,
.cv-fade-leave-active {
  transition: opacity 0.4s;
}
.cv-fade-enter-from,
.cv-fade-leave-to {
  opacity: 0;
}
.cv-description-text--address,
.cv-description-about--details {
  margin-bottom: 24px;
  font-size: 1.5rem;
  font-size: 18px;
  line-height: 28px;
}
.swapcard-infotext {
  font-size: 14px;
  line-height: 24px;
  color: #303139;
  font-family: "degular";
}
.cv-infobox-toggler-wrapper {
  margin-bottom: 40px;
  background: #ededed;
  padding: 24px;
}
.cv-infobox-toggler-wrapper .cv-infobox-headline {
  font-family: "Degular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 34px;
  color: #121212;
  margin-bottom: 8px;
  display: flex;
}
.cv-infobox-toggler-wrapper .cv-infobox-headline img {
  margin-left: 14px;
}
.cv-infobox-toggler-wrapper .cv-infobox-intro {
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #121212;
}
.cv-infobox-toggler-wrapper .button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 8px;
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler {
  display: flex;
  align-items: center;
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler .image-container {
  margin-left: 8px;
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler .image-container img {
  transition: transform 0.3s ease;
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler .image-container.open-box img {
  transform: rotate(180deg);
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler .button-text {
  cursor: pointer;
  font-family: "Degular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $color-yearly-accent;
}
.cv-infobox-toggler-wrapper .cv-infobox-toggler .button-text::after {
  content: "";
  width: 100%;
  height: 1px;
  background: $color-yearly-accent;
  display: block;
  margin-top: 6px;
}
.cv-infobox-toggler-wrapper .cv-infobox-content {
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #121212;
}
.cv-infobox-toggler-wrapper .cv-infobox-content:not(.cv-infobox-visible) {
  display: none;
}

.cv-contact .cv-formbox .cv-headline {
  margin-bottom: 16px;
}

.cv-contact .cv-formbox .cv-form-columns,
.cv-contact .cv-formbox .cv-field.cv-field-radio {
  margin-bottom: 24px;
}

.cv-contact .cv-formbox .cv-form-columns:last-child {
  margin-bottom: 0;
}
.cv-contact
  .cv-formbox
  .cv-field.cv-field-select.cv-form-column.full-width-select {
  width: 100%;
}

.cv-contact .cv-field-checkbox__text {
  margin-left: 8px;
}

.cv-infobox-toggler {
  cursor: pointer;
}
</style>
