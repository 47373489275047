<template>
  <div class="cv-invitation" :class="{ disabled: !canJoin }">
    <div class="invitation-container">
      <div class="text-container">
        <h3 class="ticket-title">
          {{ invitation.efa_session["msevtmgt_name"] }}
        </h3>
        <div
          v-if="invitation.efa_session['msevtmgt_detaileddescription']"
          v-html="cleanHTML"
          class="invitation-description"
        ></div>
        <p v-if="invitation.efa_session['efa_sessionsupportedby']">
          <span>{{ $t("invitation_from") }}</span
          >{{ invitation.efa_session["efa_sessionsupportedby"] }}
        </p>
        <p v-if="location">
          <span>{{ $t("invitation_location") }}</span
          >{{ location }}
        </p>
        <p>
          <span>{{ $t("invitation_date") }}</span
          >{{ dateAndTime(invitation) }}
        </p>
        <div class="ticket-fullybooked" v-if="isFullyBooked">
          <p class="ticket-state">
            <span></span>{{ $t("invitation_fully_booked") }}
          </p>

          <p v-if="!isAccepted && invitedJoinDeadline && canJoin">
            You can accept this invitation before
            {{ formatJoinDeadline(invitedJoinDeadline) }}
          </p>

          <p v-if="isOnWaitlist && !canJoin">
            You are currently on position {{ waitlistPosition }}
          </p>

          <p v-if="!isAccepted && !isOnWaitlist">
            {{
              $t(
                invitation.efa_session["efa_waitlistlisted"] === 1
                  ? "waitlist_one"
                  : "waitlist_other",
                { count: invitation.efa_session["efa_waitlistlisted"] }
              )
            }}
          </p>
        </div>
      </div>
      <div v-if="canJoin" class="button-container">
        <button v-if="isAccepted" class="cv-ticket__button cv-button selected">
          {{ $t("invitation_accepted") }}
        </button>
        <button
          v-else
          :disabled="isLoading"
          class="cv-ticket__button cv-button"
          @click="accept(invitation)"
        >
          {{ $t("invitation_accept") }}
        </button>
        <button
          v-if="isRejected"
          :disabled="isLoading"
          class="cv-ticket__button cv-button selected-reject"
        >
          {{ $t("invitation_rejected") }}
        </button>
        <button
          v-else
          class="cv-ticket__button cv-button"
          :disabled="isLoading"
          @click="reject(invitation)"
        >
          {{ $t("invitation_reject") }}
        </button>
      </div>

      <div v-else class="no-shrink">
        <button
          v-if="!isOnWaitlist"
          class="cv-ticket__button cv-button selected"
          :disabled="isLoading"
          @click="joinWaitlist(invitation)"
        >
          Join Waitlist
        </button>

        <button
          v-else
          class="cv-ticket__button cv-button"
          :disabled="isLoading"
          @click="reject(invitation)"
        >
          {{ $t("invitation_reject") }}
        </button>
      </div>
    </div>
    <p
      class="invitation-accepted-text"
      :class="'text-' + this.invitation.efa_session['msevtmgt_sessionid']"
    >
      {{ $t("invitation_accept_text") }}
    </p>
  </div>
</template>

<script>
import sanitizeHtml from "sanitize-html";

export default {
  props: {
    invitation: Object,
  },
  data() {
    return { isLoading: false };
  },
  computed: {
    isAccepted() {
      return this.$store.getters["isSelectedInvitation"](this.invitation);
    },
    isRejected() {
      return this.$store.getters["isRejectedInvitation"](this.invitation);
    },

    isOnWaitlist() {
      return this.waitlistPosition !== -1;
    },

    waitlistPosition() {
      return this.$store.getters["waitlistPosition"](this.invitation);
    },

    invitedJoinDeadline() {
      const invitedOn = this.$store.getters["waitlistInvitedOn"](
        this.invitation
      );

      if (!invitedOn) {
        return null;
      }

      const invitedOnDate = new Date(invitedOn);
      invitedOnDate.setDate(invitedOnDate.getDate() + 3);
      return invitedOnDate;
    },

    canJoin() {
      if (this.isAccepted) {
        return true;
      }

      const beforeDeadline = this.invitedJoinDeadline
        ? new Date() <= this.invitedJoinDeadline
        : true;

      const isOnWaitlist = this.isOnWaitlist;
      const isReserved = this.$store.getters["isReserved"](this.invitation);
      return (
        this.isFullyBooked === false ||
        (isOnWaitlist && isReserved && beforeDeadline)
      );
    },

    isJoining() {
      return this.invitation.statuscode === 221120003;
    },

    isFullyBooked() {
      return this.invitation.efa_session.efa_waitlistcount <= 0;
    },

    cleanHTML() {
      return sanitizeHtml(
        this.invitation.efa_session["msevtmgt_detaileddescription"],
        {
          allowedTags: ["br", "div", "p", "ol", "ul", "li"],
        }
      );
    },
    location() {
      return this.invitation.efa_session[
        "_msevtmgt_room_value@OData.Community.Display.V1.FormattedValue"
      ];
    },
  },
  methods: {
    formatJoinDeadline(deadline) {
      if (!deadline) {
        return "";
      }

      const day = deadline.getUTCDate().toString().padStart(2, "0");
      const month = (deadline.getUTCMonth() + 1).toString().padStart(2, "0");
      const year = deadline.getUTCFullYear();

      return `${day}.${month}.${year}`;
    },

    async accept(invitation) {
      this.isLoading = true;
      const invitations = await this.$store
        .dispatch("invitationSessions")
        .catch(() => {
          this.isLoading = false;
          return [];
        });

      const currentInvitation = invitations.find(
        (i) => i.efa_sessioninvitationid === invitation.efa_sessioninvitationid
      );

      console.log({ currentInvitation });

      if (!currentInvitation) {
        return;
      }

      const isListed = currentInvitation?.statuscode === 221120005;
      const isReserved = this.$store.getters["isReserved"](currentInvitation);
      const waitlistCount = currentInvitation.efa_session.efa_waitlistcount;
      const canJoin = waitlistCount > 0 || (isListed && isReserved);

      if (!canJoin) {
        return;
      }

      await this.$store.dispatch("acceptInvitation", invitation).finally(() => {
        this.isLoading = false;
      });

      const text = document.querySelector(
        ".text-" + invitation.efa_session["msevtmgt_sessionid"]
      );

      if (!text) return;

      text.style.opacity = 1;

      setTimeout(() => {
        text.style.opacity = 0;
      }, 5000);
    },
    reject(invitation) {
      this.$store.dispatch("rejectInvitation", invitation);
      const text = document.querySelector(
        ".text-" + invitation.efa_session["msevtmgt_sessionid"]
      );

      if (!text) return;

      text.style.opacity = 1;

      setTimeout(() => {
        text.style.opacity = 0;
      }, 5000);
    },

    joinWaitlist(invitation) {
      this.$store.dispatch("joinWaitlist", invitation);
      const text = document.querySelector(
        ".text-" + invitation.efa_session["msevtmgt_sessionid"]
      );

      if (!text) return;

      text.style.opacity = 1;

      setTimeout(() => {
        text.style.opacity = 0;
      }, 5000);
    },
    dateAndTime(invitation) {
      const startTime = invitation.efa_session["msevtmgt_starttime"];
      const endTime = invitation.efa_session["msevtmgt_endtime"];

      let date;

      if (startTime && endTime) {
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        if (
          startDate.toDateString("YYYY-MM-DD") ==
          endDate.toDateString("YYYY-MM-DD")
        ) {
          date =
            startDate.toLocaleDateString("en-us", { weekday: "short" }) +
            ". " +
            startDate.getUTCDate() +
            "." +
            (startDate.getUTCMonth() + 1) +
            "." +
            startDate.getUTCFullYear() +
            " " +
            startDate.getUTCHours() +
            ":" +
            (startDate.getUTCMinutes() == 0
              ? "00"
              : startDate.getUTCMinutes()) +
            " - " +
            endDate.getUTCHours() +
            ":" +
            (endDate.getUTCMinutes() == 0 ? "00" : endDate.getUTCMinutes());
          return date;
        } else {
          date =
            startDate.toLocaleDateString("en-us", { weekday: "short" }) +
            ". " +
            startDate.getUTCDate() +
            "." +
            (startDate.getUTCMonth() + 1) +
            "." +
            startDate.getUTCFullYear() +
            " " +
            startDate.getUTCHours() +
            ":" +
            (startDate.getUTCMinutes() == 0
              ? "00"
              : startDate.getUTCMinutes()) +
            " - " +
            endDate.toLocaleDateString("en-us", { weekday: "short" }) +
            ". " +
            endDate.getUTCDate() +
            "." +
            (endDate.getUTCMonth() + 1) +
            "." +
            endDate.getUTCFullYear() +
            " " +
            endDate.getUTCHours() +
            ":" +
            (endDate.getUTCMinutes() == 0 ? "00" : endDate.getUTCMinutes());
          return date;
        }
      } else {
        date = null;
        return date;
      }
    },
  },
  mounted() {
    this.dateAndTime(this.invitation);
  },
};
</script>

<style lang="scss">
.cv-invitation {
  width: 100%;

  .invitation-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (max-width: 850px) {
    .invitation-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .button-container {
      align-self: flex-end;
      flex-shrink: 0;
    }
  }

  .invitation-accepted-text {
    text-align: right;
    opacity: 0;
  }

  .invitation-description {
    margin-bottom: 8px;
  }

  .text-container {
    .ticket-title {
      color: $color-headings-dark;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: block;
      font-weight: 700;
      margin-bottom: 0;
      margin-bottom: 8px;
    }

    p {
      margin-top: 0;
      margin-bottom: 8px;
      color: $color-body-1;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      &:last-of-type {
        margin-bottom: 0;
      }

      span {
        color: $color-body-2;
        display: inline-block;
        margin-right: 8px;
      }
    }

    .ticket-state {
      display: flex;
      align-items: center;

      span {
        width: 8px;
        height: 8px;
        background-color: $color-yearly-accent;
        border-radius: 100%;
      }
    }
  }

  .no-shrink {
    flex-shrink: 0;
  }

  .button-container {
    display: flex;
    margin-top: 32px;
    flex-shrink: 0;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .cv-ticket__button {
      border: 2px solid #000000;
      border-radius: 2px;
      background: #fff;
      color: #000;
      margin-bottom: 0;
      transition: background-color 0.3s ease, color 0.3s ease;
      display: flex;

      &:first-of-type {
        margin-right: 16px;
      }

      &:hover {
        background: #000;
        color: #fff;
      }

      &.selected {
        background: #000;
        color: #fff;
        pointer-events: none;

        &::after {
          content: "";
          background-image: url("../assets/sass/ic_checkmark.svg");
          background-repeat: no-repeat;
          background-size: 24px;
          height: 24px;
          width: 24px;
          margin-left: 16px;
          display: block;
        }
      }

      &.selected-reject {
        background: #000;
        color: #fff;
        pointer-events: none;
      }
    }
  }

  &.disabled {
    .ticket-title {
      color: $color-body-2;
    }
  }
}

.ticket-fullybooked {
  display: flex;
  gap: 16px;
}
</style>
