<template>
  <form
    class="cv-form"
    :class="{ 'cv-form--loading': loading }"
    @submit.prevent="submit"
  >
    <section class="cv-form__fields" :class="styling">
      <h3 v-if="cvHeadline" class="cv-headline">{{ cvHeadline }}</h3>
      <p v-if="description" class="cv-description-about--details">
        {{ description }}
      </p>
      <slot />
    </section>

    <section class="cv-form__buttons">
      <div v-if="backButton" class="back-button btn">
        <router-link @click="submitBack" :to="{ name: backButtonRoute }">
          <span>{{ backButtonLabel }}</span>
        </router-link>
      </div>
      <button type="submit" class="button-wrapper btn">
        <span>{{ $t(submitValue) }}</span>
      </button>
    </section>
  </form>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    submitValue: {
      type: String,
      default: "submit",
    },
    styling: String,
    backButton: Boolean,
    backButtonLabel: String,
    backButtonMethod: null,
    backButtonRoute: String,
    cvHeadline: String,
    description: String,
  },
  methods: {
    submit(e) {
      if (this.loading) {
        e.stopImmediatePropagation();
      }
    },
    submitBack() {
      this.backButtonMethod();
    },
  },
};
</script>

<style lang="scss">
.cv-form {
  button[type="submit"] {
    @extend %button;
    width: 100%;

    &:hover {
      background-color: black;
    }
  }

  &.cv-form--loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.cv-form__fields {
  margin-bottom: unit(1);

  & > .cv-field:last-child {
    margin-bottom: 0;
  }
}

.cv-form--singleline {
  display: flex;
  align-items: end;

  .cv-form__fields {
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: unit(1);
  }
}

.cv-form__fields.formbox-style {
  border: 1px solid black;
  padding: unit(2);
}
</style>
