<template>
  <div class="cv-checkout-completed">
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--highlight">{{ $t("you_are_in") }}</h2>
      <div>
        {{ $t("ticket_paid_text") }}
        <br /><br />
        {{ $t("check_spam") }}
      </div>
    </div>
    <cv-alpbach-button></cv-alpbach-button>
  </div>
</template>

<script>
import CvAlpbachButton from "../components/AlpbachButton.vue";
export default {
  components: {
    CvAlpbachButton,
  },

  mounted() {
    this.$store.dispatch("ticket/paymentComplete");
  },
};
</script>

<style lang="scss"></style>
