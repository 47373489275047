<template>
  <div class="cv-checkout-survey">
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--highlight">{{ $t("thank_you") }}</h2>
      <div>
        {{ $t("ticket_survey_required_text") }}
      </div>
    </div>
    <cv-alpbach-button></cv-alpbach-button>
  </div>
</template>

<script>
import CvAlpbachButton from "../components/AlpbachButton.vue";
export default {
  components: {
    CvAlpbachButton,
  },
};
</script>

<style lang="scss"></style>
