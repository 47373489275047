<template>
  <div class="cv-register">
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--highlight">
        {{ $t("welcome_ticketing_system_headline") }}
      </h2>
      <!-- <p class="register-intro">The following ticket has been saved for you</p> -->
      <!-- <div>{{ $t("tickets_on_file") }}</div> -->
    </div>
    <!-- <cv-ticket-permit-info class="cv-margin" /> -->
    <div class="cv-formbox">
      <div v-if="error" class="cv-style--error cv-margin--small">
        {{ error }}
      </div>
      <cv-form
        @submit="submitData"
        styling="formbox-style"
        :backButton="true"
        :backButtonLabel="$t('back_button_to_start')"
        :backButtonMethod="setPrevStep"
        backButtonRoute="Home"
        :cvHeadline="$t('proceed_registration')"
        :description="$t('user_text_email_infotext')"
      >
        <cv-input-field
          v-model="user.mail"
          type="email"
          :required="true"
          :label="$t('user_mail')"
          title="email"
        />
        <cv-input-field
          v-model="user.firstName"
          :required="true"
          :label="$t('user_firstname')"
          title="firstname"
        />
        <cv-input-field
          v-model="user.lastName"
          :required="true"
          :label="$t('user_lastname')"
          title="lastname"
        />

        <cv-infobox>
          {{ $t("register_token_expire_info") }}
        </cv-infobox>
      </cv-form>
    </div>
  </div>
</template>

<script>
import CvForm from "../components/form/Form.vue";
import CvInputField from "../components/form/InputField.vue";
import CvInfobox from "../components/Infobox.vue";

export default {
  components: {
    CvForm,
    CvInputField,
    CvInfobox,
  },
  data: () => ({
    user: {
      mail: "",
      firstName: "",
      lastName: "",
    },
    error: null,
  }),
  methods: {
    submitData() {
      window.lintrk?.("track", { conversion_id: 16656756 }); 
      this.$store.dispatch("createUser", this.user);
      this.saveUserData();
    },
    // Set prev step by clicking on back button
    setPrevStep() {
      this.$store.commit("step", 1);
      this.saveUserData();
    },
    saveUserData() {
      this.$store.dispatch("saveUserDataInLocalStorage", {
        key: "registerMail",
        userData: this.user.mail,
      });
      this.$store.dispatch("saveUserDataInLocalStorage", {
        key: "registerFirstName",
        userData: this.user.firstName,
      });
      this.$store.dispatch("saveUserDataInLocalStorage", {
        key: "registerLastName",
        userData: this.user.lastName,
      });
    },
  },
  mounted() {
    //TODO noch nicht perfekt. man sollte zwar nur hier mit einem JWT laden wenn der JWT ungültig ist, aber man prpft nicht konkret ob er auch wirklich dieser Fehler ist.
    if (this.$store.state.tokenJWT) {
      let jwtData = this.$store.getters.decodedToken;
      this.user.mail = jwtData.email;
      this.user.firstName = jwtData.given_name;
      this.user.lastName = jwtData.family_name;
      this.error =
        "Token has expired. Please resubmit this form to receive an email with a new confirmation link. Keep in mind that the new link is only valid for 6 hours.";
    }
  },
};
</script>

<style lang="scss">
.cv-register .cv-form__buttons {
  width: 100% !important;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.cv-register .cv-form__buttons .btn {
  width: 100% !important;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 50% !important;
  }
}
.cv-register .cv-form__buttons .back-button {
  order: 2;
  margin-top: 1rem;

  @media (min-width: 768px) {
    margin-right: 12px;
    margin-top: 0;
    order: 1;
  }
}
.cv-register .cv-form__buttons .button-wrapper {
  width: auto !important;
  order: 1;

  @media (min-width: 768px) {
    margin-left: 12px;
    width: 50% !important;
    order: 2;
  }
}
.cv-register .cv-description-about--details {
  padding-bottom: 24px;
  font-size: 1.5rem;
  font-size: 18px;
  line-height: 28px;
}
.cv-register .register-intro {
  margin: 0;
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #303139;
}
.cv-register .cv-formbox {
  padding: 0;
  border: 0;
}
.cv-register .cv-form__fields.formbox-style {
  margin-bottom: 40px;
}
.cv-register .cv-formbox .cv-headline {
  margin-bottom: 16px;
}
</style>
