<template>
  <div class="cv-ticket-state">
    <div
      v-if="isSoldout"
      class="cv-ticket-state__step cv-ticket--soldout cv-ticket-state--inactive"
    >
      <div class="cv-ticket-state__icon">
        <span class="bh-font-stop2"></span>
      </div>
      <div class="cv-ticket-state__label">
        {{ $t("ticket_permission_soldout") }}
      </div>
    </div>
    <div
      v-else-if="isRefused"
      class="cv-ticket-state__step cv-ticket--refused cv-ticket-state--inactive"
    >
      <div class="cv-ticket-state__icon">
        <span class="bh-font-stop2"></span>
      </div>
      <div class="cv-ticket-state__label">
        {{ $t("ticket_permission_refused") }}
      </div>
    </div>
    <template v-else>
      <div
        v-if="needsSurvey"
        class="cv-ticket-state__step cv-ticket-state--survey"
        :class="{
          'cv-ticket-state--active': hasSurveyCompleted,
          'cv-ticket-state--pending': isSurveyPending,
        }"
      >
        <div class="cv-ticket-state__icon">
          <span
            :class="{
              'bh-font-bar-graph-2': !isSurveyPending && !hasSurveyCompleted,
              'bh-font-loader': isSurveyPending,
              'bh-font-square-check': hasSurveyCompleted,
            }"
          ></span>
        </div>
        <div class="cv-ticket-state__label">
          <template v-if="hasSurveyCompleted">
            {{ $t("ticket_needs_survey_completed") }}
          </template>
          <template v-else-if="isSurveyPending">
            {{ $t("ticket_needs_survey_pending") }}
          </template>
          <template v-else>
            {{ $t("ticket_needs_survey") }}
          </template>
        </div>
      </div>
      <div
        v-if="needsApproval"
        class="cv-ticket-state__step cv-ticket-state--approval"
        :class="{
          'cv-ticket-state--active': isApproved,
          'cv-ticket-state--pending': isApprovalPending,
        }"
      >
        <div class="cv-ticket-state__icon">
          <span
            :class="{
              'bh-font-unlock': !isApprovalPending && !isApproved,
              'bh-font-loader': isApprovalPending,
              'bh-font-square-check': isApproved,
            }"
          ></span>
        </div>
        <div class="cv-ticket-state__label">
          <template v-if="isApproved">
            {{ $t("ticket_permission_by_efa_approved") }}
          </template>
          <template v-else-if="isApprovalPending">
            {{ $t("ticket_permission_by_efa_pending") }}
          </template>
          <template v-else>
            {{ $t("ticket_permission_by_efa") }}
          </template>
        </div>
      </div>
      <div v-if="!needsApproval">
        <div class="label-approved">
          {{ $t("ticket_approval_no") }}
        </div>
      </div>

      <div
        class="cv-ticket-state__step cv-ticket-state--bookable"
        :class="{
          'cv-ticket-state--active': isBookableActive,
          'cv-ticket-state--pending': isBookablePending,
        }"
      >
        <div class="cv-ticket-state__icon">
          <span
            :class="{
              'bh-font-cart': !isBookablePending && !isPaid,
              'bh-font-loader': isBookablePending,
              'bh-font-square-check': isPaid,
            }"
          ></span>
        </div>
        <div class="cv-ticket-state__label">
          <template v-if="isPaid">
            {{ $t("ticket_permission_paid") }}
          </template>
          <template v-else-if="isBookablePending">
            {{ $t("ticket_permission_payment_pending") }}
          </template>
          <template v-else-if="!isBookableActive">
            {{ $t("ticket_permission_bookableafter") }}
          </template>
          <template v-else>
            {{ $t("ticket_permission_bookable") }}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  approvalRequired,
  getApprovedState,
  surveyRequired,
  getSurveyState,
  isSoldout,
  isBookable,
  getBookableState,
} from "../utils/ticketstates";

export default {
  props: {
    ticket: Object,
  },
  computed: {
    isSoldout() {
      return isSoldout(this.ticket);
    },
    needsApproval() {
      return approvalRequired(this.ticket);
    },
    isApprovalPending() {
      return getApprovedState(this.ticket) == 0;
    },
    isApproved() {
      return getApprovedState(this.ticket) == 1;
    },
    isRefused() {
      return getApprovedState(this.ticket) == -1;
    },
    needsSurvey() {
      return surveyRequired(this.ticket);
    },
    isSurveyPending() {
      return getSurveyState(this.ticket) == 0;
    },
    hasSurveyCompleted() {
      return getSurveyState(this.ticket) == 1;
    },
    isBookableActive() {
      return isBookable(this.ticket) && getBookableState(this.ticket) != 0;
    },
    isBookablePending() {
      return getBookableState(this.ticket) == 0;
    },
    isPaid() {
      return getBookableState(this.ticket) == 1;
    },
  },
};
</script>

<style lang="scss">
.cv-ticket-state {
  display: flex;
}
.cv-ticket-state__step {
  width: auto;
  flex-grow: 0;
  display: flex;
  // justify-content: center;
}

.cv-ticket-state__icon {
  margin-bottom: unit(0.5);
  display: flex;
  align-items: center;

  // &::after {
  //   // content: "";
  //   // height: 1px;
  //   // flex-grow: 1;
  //   // background: rgba($color-gold, 0.2);
  //   // margin: 0 unit(1);
  //   content: "";
  //   background-image: url("../assets/sass/ic_dot_yearlyaccent.svg");
  //   background-repeat: no-repeat;
  //   background-size: 8px;
  //   height: 8px;
  //   width: 8px;
  //   margin-left: 16px;
  //   display: block;
  // }
}
.cv-ticket-state__step {
  color: $color-gray;

  &:last-child {
    .cv-ticket-state__icon:after {
      background: transparent;
    }
  }
}

.cv-ticket-state__label {
  font-size: 16px;
  line-height: 24px;
  font-family: "degular";
  font-weight: 400;
  color: $color-body-1;
}

.cv-ticket-state__icon {
  display: none;
}

.cv-ticket-state--bookable {
  display: flex;
  align-items: center;
  &::before {
    margin-right: 8px;
    content: "";
    background-image: url("../assets/sass/ic_dot_yearlyaccent.svg");
    background-repeat: no-repeat;
    background-size: 8px;
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    margin-left: 16px;
    display: block;
  }
}

.cv-ticket-state--approval .cv-ticket-state__icon {
  display: none;
}

.cv-ticket-state--active {
  // color: $color-green;
  color: $color-body-1;
}
.cv-ticket-state--pending {
  // color: $color-orange;
  color: $color-body-1;
}
.cv-ticket-state--inactive {
  // color: $color-red;
  color: $color-body-1;
}

.cv-ticket-state--survey {
  margin-right: 8px;
}
</style>
