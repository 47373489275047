<template>
  <div class="cv-field cv-field-checkbox">
    <label class="cv-field-checkbox__input">
      <input
        :id="name"
        type="checkbox"
        :required="required"
        :checked="modelValue"
        @input="onChanged"
        @blur="onBlur"
      />
      
      <div class="cv-field-checkbox__label--wrapper">
        <span v-if="label" class="cv-field-checkbox__label">{{ label }}</span>
        <span v-if="info" class="cv-field-checkbox__info">{{ info }}</span>
        <span v-else class="cv-field-checkbox__text">
          <slot /><template v-if="required"> <span aria-hidden="true"> *</span></template>
        </span>
      </div>
    </label>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import { reportValidity } from "../../utils";

export default {
  props: {
    modelValue: Boolean,
    label: String,
    info: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    name: "",
    blurred: false,
    hasValidatedOnBlur: false,
    dirty: false,
  }),
  methods: {
    onChanged(e) {
      this.$emit("update:modelValue", e.currentTarget.checked);

      this.dirty = true;
      if (this.blurred) {
        reportValidity(e.target);
      }
    },
    
    onBlur(e) {
      this.blurred = true;

      if (this.dirty && !this.hasValidatedOnBlur) {
        reportValidity(e.target);
        this.hasValidatedOnBlur = true;
      }
    }
  },
  beforeMount() {
    this.name = uniqueId("radio_");
  },
};
</script>

<style lang="scss">
.cv-field-checkbox__label--wrapper {
  line-height: 0;
}

.cv-field-checkbox.cv-field-checkbox {
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-weight: bold;
    cursor: pointer;
  }
}
.cv-field-checkbox__input {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border: 2px solid black;
    cursor: pointer;
    transition: all 0.4s;
    flex-shrink: 0;

    &:checked + .cv-field-checkbox__faker {
      &:before {
        content: "";
        display: block;
        height: 11px;
        width: 11px;
        border-radius: 1px;
        background: black;
      }
    }
    &:focus + .cv-field-checkbox__faker {
      border: 2px solid black;
    }
  }
}
.cv-field-checkbox__faker {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 1px;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.4s;

  &:before {
    content: "";
    display: block;
    height: 11px;
    width: 11px;
    border-radius: 1px;
    background: transparent;
    transition: all 0.4s;
  }
}
.cv-field-checkbox__label {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cv-field-checkbox__text {
  font-family: "degular";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #303139;

  a {
    color: #303139;
  }
}
.cv-field-checkbox__info {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 10px;
}
</style>
