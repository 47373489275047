<template>
  <div class="cv-infobox">
    <div class="cv-infobox__icon">
      <span class="bh-font-info-circle"></span>
    </div>
    <p class="cv-infobox__content">
      <slot></slot>
    </p>
  </div>
</template>

<style lang="scss">
.cv-infobox {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 28px;
  color: $color-body-1;

  @extend %text-infobox;
}

.cv-infobox__icon {
  width: 50px;
  font-size: unit(1.5);
  flex-shrink: 0;
  display: none;
}

.cv-infobox__content {
  flex-grow: 1;
  font-family: "degular";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}

.cv-field-checkbox__text {
  font-family: "degular";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
