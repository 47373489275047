<template>
  <div class="cv-contactlist">
    <h1>Zugeordnete Kontakte</h1>
    <ul class="cv-contactlist-list">
      <cv-contact-list-item
        v-for="contact in contactlist"
        :key="contact.contactId"
        :contact="contact"
        class="cv-contactlist-item"
        @click="selectItem(contact)"
      />
    </ul>
  </div>
</template>

<script>
import CvContactListItem from "../components/ContactListItem";
import { mapState } from "vuex";

export default {
  components: {
    CvContactListItem,
  },
  computed: {
    ...mapState(["contactlist"]),
  },
  methods: {
    selectItem(contact) {
      let acceptedAgreementsBool = false;
      if (
        contact["efa_ticketpermit_contactid_contact"] &&
        contact["efa_ticketpermit_contactid_contact"][0] &&
        contact["efa_ticketpermit_contactid_contact"][0]["efa_gdpraccepted"] &&
        contact["efa_ticketpermit_contactid_contact"][0][
          "efa_conditionsaccepted"
        ] &&
        contact["efa_codeofconductaccepted"] &&
        contact["new_agbakzeptiert"] &&
        contact["new_datenschutzok"]
      ) {
        acceptedAgreementsBool = true;
      }
      this.$store.dispatch("selectContact", {
        contact: contact,
        acceptedAgreements: acceptedAgreementsBool,
      });
    },
  },
};
</script>

<style lang="scss">
.cv-contactlist-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cv-contactlist {
  h1 {
    font-size: 38px;
    line-height: 38px;
    font-family: "degular";
    font-weight: 700;
    color: $color-headings-dark;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      font-size: 58px;
      line-height: 58px;
    }
  }

  .cv-contactlist-item {
    border: 1px solid black;
    border-radius: 0px;
    padding: 16px;
    background: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 768px) {
      padding: 32px;
      flex-direction: row;
    }

    &::before {
      display: none;
    }
  }

  .cv-contactlist-item__address-container {
    display: flex;
  }

  .cv-contactlist-item__name {
    color: $color-headings-dark;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: block;
    font-weight: 700;
    font-family: "degular";
    margin-bottom: 0.75rem;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 32px;
    }
  }

  .cv-contactlist-item__address-box {
    font-family: "degular";
  }

  .cv-contactlist-item__address {
    font-size: 14px;
  }

  .cv-contactlist-item__icon {
    font-family: "degular";
    padding-top: 7px;
  }

  .cv-contactlist-item__tools {
    align-self: flex-start;
    margin-top: 2rem;
    width: 100%;

    @media (min-width: 768px) {
      align-self: flex-end;
      margin-top: 0;
      width: auto;
    }
  }
}
</style>
