<template>
  <div class="cv-home">
    <!--
      <div class="cv-style--date-table-wrapper cv-margin">
        <div class="cv-style--highlight">Timeline of #EFA21</div>
        <table class="v-style--date-table">
          <tbody>
            <tr><td>Arts & Culture (ART)</td><td>August 18 - September 03, 2021</td></tr>
            <tr><td>Seminar Week (SEM)</td><td>August 18 - 24, 2021</td></tr>
            <tr><td>Tyrol Days (TYR)</td><td>August 21 - 22, 2021</td></tr>
            <tr><td>Digital / Hybrid Conference</td><td>August 24 - September 03, 2021</td></tr>
            <tr><td>Opening </td><td>August 24, 2021</td></tr>
            <tr><td>Intro Day (INTRO) and Artists in Discourse (MEET)</td><td>August 25, 2021</td></tr>
            <tr><td>Technology (TEC)</td><td>August 26 - 27, 2021</td></tr>
            <tr><td>Health (MED)</td><td>August 27 - 29, 2021</td></tr>
            <tr><td>Economy (ECN)</td><td>August 29 - 31, 2021<br>Departure: 1 September</td></tr>
            <tr><td>Politics (POL)</td><td>September 01 - 03, 2021</td></tr>
          </tbody>
        </table>
      </div>
    -->
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--highlight">
        {{ $t("welcome_ticketing_system_headline") }}
      </h2>
      <!-- <div>
        {{ $t("welcome_ticketing_system_text") }}
      </div> -->
    </div>

    <!-- <div class="cv-formbox">
      <div class="cv-headline">{{ $t("your_ticket_for_the_digital_conference") }}</div>
      <router-link
        class="cv-button"
        :to="{ name: 'direktticket', params: { token: '2021-PLT1-OS5F' } }"
      >
        {{ $t('get_your_digital_ticket') }}
      </router-link>
    </div> -->

    <!-- <div class="cv-headline">{{ $t("get_your_ticket") }}</div> -->

    <!-- <cv-infobox class="cv-margin">
      {{ $t("you_can_select_more_tickets_later") }}<br /><br />
    </cv-infobox> -->

    <!-- Available Ticketlinks -->
    <div v-for="link in modulTickets" :key="link">
      <cv-start-ticket :link="link" />
    </div>


    <h2 class="cv-headline personal-ticket-headline">
      {{ $t("day_tickets_headline") }}
    </h2>

    <p class="personal-ticket-p">
      {{ $t("day_tickets_description") }}
    </p>
    <div v-for="link in dayTickets" :key="link">
      <cv-start-ticket :link="link" />
    </div>

    <!-- Ticketcode Input -->
    <div class="cv-formbox">
      <div class="cv-headline">{{ $t("your_ticket_code") }}</div>
      <cv-input-field v-model="ticketPermit" />
    </div>
  </div>
  <cv-form
    class="cv-form--submit-button-home"
    :class="computedClass"
    @submit="submitData"
  >
  </cv-form>
</template>

<script>
import CvForm from "../components/form/Form.vue";
import CvInputField from "../components/form/InputField.vue";
import CvStartTicket from "../components/StartTicket.vue";

export default {
  components: {
    CvForm,
    CvInputField,
    CvStartTicket,
  },
  data: () => ({
    ticketPermit: "",
  }),
  computed: {
    ticketLinks() {
      return this.$store.getters.ticketLinks;
    },
    dayTickets() {
      return this.ticketLinks?.filter((ticket) => {
        return (
          ticket["ticket@OData.Community.Display.V1.FormattedValue"].indexOf(
            "August"
          ) !== -1
        );
      });
    },
    modulTickets() {
      return this.ticketLinks?.filter((ticket) => {
        return (
          ticket["ticket@OData.Community.Display.V1.FormattedValue"].indexOf(
            "August"
          ) === -1
        );
      });
    },
    selectedTicketCodes() {
      return this.$store.getters.selectedTicketCodes;
    },
    computedClass() {
      let className = "disabled";

      if (
        this.ticketPermit != "" ||
        this.$store.state.firstSelectedTicketCodes.length > 0
      ) {
        className = "active";
      }

      return className;
    },
  },
  methods: {
    async submitData() {
      if (this.ticketPermit) {
        await this.$store.dispatch("validateLink", {
          ticketPermit: this.ticketPermit,
        });
      } else {
        await this.$store.dispatch("validateLink", {
          ticketPermit: this.selectedTicketCodes[0],
        });
      }
    },
  },
  mounted() {
    // if (this.$store.state.ticketPermit) {
    //   this.ticketPermit = this.$store.state.ticketPermit.code;
    // }
  },
};
</script>

<style lang="scss">
.bh-font-ticket {
  &::before {
    content: "";
    background-image: url("../assets/sass/ticket_shop_violet.png");
    background-repeat: no-repeat;
    background-size: 32px;
    height: 32px;
    width: 32px;
    display: block;
    margin-right: 19px;
    transition: transform 200ms ease-in-out;
  }
}
.cv-home .cv-ticket__button.cv-ticket__button {
  width: 100%;
  margin-bottom: 0;

  @media (min-width: 768px) {
    width: auto;
    margin-bottom: 0;
  }
}

.cv-home .cv-start-ticket {
  margin-bottom: 2rem;
}

.cv-form--submit-button-home .cv-form__buttons {
  width: 100%;
  margin-top: 3rem;
}

.cv-form--submit-button-home .cv-form__buttons .button-wrapper.btn {
  justify-content: center;
}

.cv-form--submit-button-home.disabled .cv-form__buttons .button-wrapper.btn {
  background-color: #b4b8b8;
  pointer-events: none;
}
</style>
